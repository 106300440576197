import React, { useEffect, useContext, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Resizable } from 're-resizable';
import { NavContext } from '../../ContextProviders/NavProvider';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { VerseOptionsContext } from '../../ContextProviders/VerseOptionsProvider';
import { AnalyticsProviderState } from '../../ContextProviders/AnalyticsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV';
import styled from 'styled-components';
import './appContainer.css';
import '../../assets/css/app.css';

import Menu from '../Menu/Menu.js';
import Dashboard from '../Dashboard/Dashboard.js';
import CollectionList from '../CollectionList/CollectionList.js';
import CollectionNote from '../CollectionNote/CollectionNote.js';
import Library from '../Library/Library.js';
import Search from '../Search/Search.js';
import Analytics from '../Analytics/Analytics.js';
import Tags from '../Tags/Tags.js';
import Verses from '../Verses/Verses.js';
import SearchResult from '../Search/SearchResult.js';
import Todo from '../Todo/Todo';
import Podcasts from '../Podcast/Podcasts';
import Podcast from '../Podcast/Podcast';
import PodcastPreview from '../Podcast/PodcastPreview';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Games from '../Games/Games';
import ChapterResources from '../ChapterResources/ChapterResources';
import Image from '../Image/Image';
import TimeSkimmer from '../TimerSkimmer/TimeSkimmer';
import WordUseReport from '../WordUseReport/WordUseReport';
import ChatGPT from '../ChatGPT/ChatGPT';
import AuthPane from '../AuthPane/AuthPane';
import GoogleReviews from '../GoogleReviews/GoogleReviews';
import TrainingPane from '../TrainingPane/TrainingPane';
import { VersesContext } from '../../ContextProviders/VersesProvider';
import { CollectionListContext } from '../../ContextProviders/CollectionListProvider';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
import { SearchContext } from '../../ContextProviders/SearchProvider';
import { BulkMoveContext } from '../../ContextProviders/BulkMoveProvider';
import VerseOptions from '../Verses/VerseOptions.js';
import io from 'socket.io-client';

const queryString = require('query-string');

const AppContain = styled.div`
	background: #ececec;
	height: 100vh;
	width: 100%;
	padding: 20px 0 30px 125px;
	overflow-x: scroll;
`;

const getListStyle = () => ({
	display: 'flex',
	width: '100%',
	height: '100%',
});

const rightHandle = () => (
	<FontAwesomeIcon
		icon={faEllipsisV}
		color='#b2b7be'
		style={{
			top: '50%',
			right: '7px',
			position: 'absolute',
		}}
	/>
);

export const AppContainer = props => {
	// Local Storage
	const [data, setData] = useState([]);
	const [todayDate, setTodayDate] = useState([]);
	const [checkActiveUser, setCheckActiveUser] = useState([]);
	const [hideMine, setHideMine] = useState(false);
	const [hideDone, setHideDone] = useState(false);
	// Global Storage
	const { state, dispatch, createCollection, handleAddNewCollectionNote, handleAddVerseToNewCollectionPane } = useContext(NavContext);
	const { optionVerseId, setOptionVerseId, optionPaneUniqueId, optionVerseProps, isOpenOptions, selectedFullLi } = useContext(VerseOptionsContext);
	const [prevElems, setPrevElems] = useState([]);

	const { authState, authDispatch, isProUser } = useContext(AuthContext);
	const { libraryState, libraryDispatch, fetchVolumesBooksNChapters } = useContext(LibraryProviderState);
	const { analyticsState, analyticsDispatch, fetchAnalytics } = useContext(AnalyticsProviderState);
	const { versesState } = useContext(VersesContext);
	const { searchState } = useContext(SearchContext);
	const { collectionState, addVerseToCn, collectionDispatch, handleAddVerseToNewCollectionNote, addBulkVerses } = useContext(CollectionsContext);
	const { bulkMoveState, bulkMoveDispatch } = useContext(BulkMoveContext);
	const today = new Date();
	const month = today.getMonth() + 1;
	const year = today.getFullYear();
	const date = today.getDate();
	const currentDate = month + '/' + date + '/' + year;
	const unique = () => {
		return new Date().getTime().toString(36);
	};
	useEffect(() => {
		if (authState.user) {
			// let checkActiveUserfetch = fetch(`${process.env.REACT_APP_BASE_URL}/analyt/checkCurrentDateUser/${authState.user.email}`)
			// 	.then(res => res.json())
			// 	.then(checkActiveUserfetch => {
			// 		setCheckActiveUser(checkActiveUserfetch);
			// 	});
			// let elements = document.getElementsByClassName('draggable-wrapper')[0].children;
			let elements = '';

			let newElement = [...elements].find(e => !prevElems.find(prevEl => prevEl.dataset.rbdDraggableId === e.dataset.rbdDraggableId));

			newElement && newElement.scrollIntoView({ behavior: 'auto' });

			setData(state);
			setPrevElems([...elements]);

			//ADDING ACTIVE USER DATA ON EVERY EVENT
			if (todayDate !== currentDate) {
				let lr = fetch(`${process.env.REACT_APP_BASE_URL}/analytics/addLoginRecord`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ email: `${authState.user.email}` }), // <-- Post parameters
				})
					.then(response => response.json())
					.then(lr => {
						setTodayDate(currentDate);
						return lr;
					});
			}
		}
	}, [state]);

	useEffect(() => {
		const handleBeforeUnload = event => {
			event.preventDefault();
			event.returnValue = ''; // Required for Chrome and Edge
		};

		const handlePopState = event => {
			const confirmLeave = window.confirm('Are you sure you want to leave this page?');
			if (!confirmLeave) {
				window.history.pushState(null, '', window.location.href); // Prevent back navigation
			}
		};

		// Ensure Safari & Edge don't ignore beforeunload
		let hasInteracted = false;
		const enableBeforeUnload = () => {
			if (!hasInteracted) {
				hasInteracted = true;
				window.addEventListener('beforeunload', handleBeforeUnload);
			}
		};

		// Always trigger beforeunload on tab close/refresh
		window.addEventListener('beforeunload', handleBeforeUnload);
		window.addEventListener('popstate', handlePopState);

		// Fix Safari & Edge by requiring user interaction first
		window.addEventListener('click', enableBeforeUnload, { once: true });
		window.addEventListener('keydown', enableBeforeUnload, { once: true });

		// Prevent back navigation initially (Safari fix)
		window.history.pushState(null, '', window.location.href);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopState);
			window.removeEventListener('click', enableBeforeUnload);
			window.removeEventListener('keydown', enableBeforeUnload);
		};
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => '';

		let parsed = queryString.parse(window.location.search);
		const prevUserEmail = localStorage.getItem('prev-email');
		const shareLinks = JSON.parse(localStorage.getItem('share-links'));
		if (shareLinks && shareLinks.pane) {
			const { pane } = shareLinks;
			if (pane === 'collectionNote' && shareLinks['shareCode']) {
				const { shareCode } = shareLinks;
				const sendTime = new Date().getTime();
				if (authState.user.id) {
					fetch(`${process.env.REACT_APP_BASE_URL}/collections/getSharedCNByCode/${authState.user.id}/${shareCode}`)
						.then(res => res.json())
						.then(collection => {
							if (collection.message && collection.message === `Cannot read property 'id' of undefined`) {
								dispatch({
									type: 'RESET_PANES',
									payload: [
										{
											key: `dashboard_${unique()}`,
											type: 'dashboard',
											socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }),
										},
									],
								});

								return alert('Sorry this collection is no longer being shared.');
							}
							if (Date.parse(collection[0].share_expires) < sendTime) {
								dispatch({
									type: 'RESET_PANES',
									payload: [
										{
											key: `dashboard_${unique()}`,
											type: 'dashboard',
											socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }),
										},
									],
								});

								return alert('Sorry this link has expired');
							}

							dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `${pane}_${unique()}`, type: pane, collectionId: null, shareCode } });
						})
						.catch(err => console.log('Error getting verses from url:', err));
					localStorage.setItem('share-links', null);
				} else {
					fetch(`${process.env.REACT_APP_BASE_URL}/collections/getSharedCNNonUser/${shareCode}`)
						.then(res => res.json())
						.then(collection => {
							if (collection.message && collection.message === `Cannot read property 'id' of undefined`) {
								dispatch({
									type: 'RESET_PANES',
									payload: [
										{
											key: `dashboard_${unique()}`,
											type: 'dashboard',
											socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }),
										},
									],
								});

								return alert('Sorry this collection is no longer being shared.');
							}
							if (Date.parse(collection[0].share_expires) < sendTime) {
								dispatch({
									type: 'RESET_PANES',
									payload: [
										{
											key: `dashboard_${unique()}`,
											type: 'dashboard',
											socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }),
										},
									],
								});

								return alert('Sorry this link has expired');
							}

							dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `${pane}_${unique()}`, type: pane, collectionId: null, shareCode } });
							dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `authPane_${unique()}`, type: 'authPane' } });
							dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `googleReviews_${unique()}`, type: 'googleReviews' } });
						})
						.catch(err => console.log('Error getting verses from url:', err));
					localStorage.setItem('share-links', null);
				}
			} else {
				dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `${pane}_${unique()}`, type: pane } });
			}

			return;
		} else {
			localStorage.setItem('share-links', null);
		}
		if (parsed && parsed['pane']) {
			let { pane } = parsed;

			if (pane === 'collectionNote' && parsed['collectionId']) {
				let { collectionId } = parsed;
				if (collectionId === 'new') {
					if (parsed['verseId'] && parsed['bookId'] && parsed['chapterId']) {
						let { verseId, bookId, chapterId } = parsed;

						fetch(`${process.env.REACT_APP_BASE_URL}/verses/getVerses/${authState.user.id}/${bookId}/${chapterId}`)
							.then(res => res.json())
							.then(verses => {
								let collectionVerse = verses.find(verse => verse.id === Number(verseId));
								if (collectionVerse) handleAddNewCollectionNote([collectionVerse]);
							})
							.catch(err => console.log('Error getting verses from url:', err));
					} else {
						createCollection(null);
					}
				} else {
					dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `${pane}_${unique()}`, type: pane, collectionId: collectionId } });
				}
			} else if (pane === 'collectionNote' && parsed['shareCode']) {
				const { shareCode } = parsed;
				const sendTime = new Date().getTime();
				fetch(`${process.env.REACT_APP_BASE_URL}/collections/getSharedCNByCode/${authState.user.id}/${shareCode}`)
					.then(res => res.json())
					.then(collection => {
						if (collection.message && collection.message === `Cannot read property 'id' of undefined`) {
							dispatch({
								type: 'RESET_PANES',
								payload: [
									{
										key: `dashboard_${unique()}`,
										type: 'dashboard',
										socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }),
									},
								],
							});

							return alert('Sorry this collection is no longer being shared.');
						}
						if (Date.parse(collection[0].share_expires) < sendTime) {
							dispatch({
								type: 'RESET_PANES',
								payload: [
									{
										key: `dashboard_${unique()}`,
										type: 'dashboard',
										socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }),
									},
								],
							});

							return alert('Sorry this link has expired');
						}

						dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `${pane}_${unique()}`, type: pane, collectionId: null, shareCode } });
					})
					.catch(err => console.log('Error getting verses from url:', err));
			} else {
				dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `${pane}_${unique()}`, type: pane } });
			}
		} else if (prevUserEmail !== authState.user.email) {
			dispatch({
				type: 'RESET_PANES',
				payload: [{ key: `dashboard_${unique()}`, type: 'dashboard', socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }) }],
			});
		} else if (!state.length) {
			dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `dashboard_${unique()}`, type: 'dashboard' } });
		}
		handleSettingsFetch();
		// window.mobileCheck() && alert('We recommend using our mobile app')
	}, []);
	const handleSettingsFetch = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/getSettings/${authState.user.id}`)
			.then(res => res.json())
			.then(settings => {
				if (settings.length > 0) {
					settings[0].hide_mine && setHideMine(settings[0].hide_mine);
					settings[0].hide_done && setHideDone(settings[0].hide_done);
				}
			})
			.catch(err => console.log(err));
	};
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list),
			[removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	const onDragEnd = result => {
		// dropped outside the list
		if (!result.destination) return;
		// reordering column
		if (result.type === 'COLUMN') {
			const info = reorder(state, result.source.index, result.destination.index);
			dispatch({ type: 'RESET_PANES', payload: info });
			return;
		}

		state.forEach((value, index) => {
			if (result.destination.droppableId === value.key && result.destination.droppableId.includes('collectionNote') && isProUser()) {
				if (bulkMoveState.length > 0 && bulkMoveState.some(e => e.draggableId === result.draggableId)) {
					let bulkVerseArr = [];
					let bulkVerseCNArr = [];
					let userId = authState.user.id;
					let uniqueKey = value.key;
					let collectionId = collectionState[value.key].collection.id;
					const editable = collectionState[value.key].collection.user_id
						? collectionState[value.key].collection.user_id === authState.user.id
							? true
							: false
						: true;
					// const editable = true;
					if (editable) {
						bulkMoveState.forEach((e, i) => {
							let verseToBeAdded;
							if (e.draggableId.includes('verses')) verseToBeAdded = versesState[e.droppableId].verses[e.index];
							else if (e.draggableId.includes('search_result')) verseToBeAdded = searchState[e.droppableId][e.index];
							else if (e.draggableId.includes('collectionNote')) verseToBeAdded = collectionState[e.droppableId].collection.verses[e.index];
							else return;

							let newCollectionNoteVerseData = {
								userId,
								uniqueKey,
								verseNumber: verseToBeAdded.number,
								chapterId: verseToBeAdded.chapter.id,
								verseId: verseToBeAdded.id,
								collectionId: collectionId,
							};
							bulkVerseArr.push(newCollectionNoteVerseData);
							bulkVerseCNArr.push(verseToBeAdded);
						});
						// console.log(bulkVerseArr)
						// if (!collectionState[value.key].collection.id) {
						// 	handleAddVerseToNewCollectionPane(verseToBeAdded, uniqueKey);
						// 	handleAddVerseToNewCollectionNote(verseToBeAdded, uniqueKey);
						// } else {
						// 	addVerseToCn(newCollectionNoteVerseData);

						// 	if (authState && authState.user && authState.user.id) {
						// 		// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'], upgrade: false }).emit('update_cn_list', { fromVerse: true, userId: authState.user.id });
						// 		// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'], upgrade: false }).emit('update_verse', { verseId: verseToBeAdded.id, userId: authState.user.id });
						// 	}
						// }
						bulkMoveDispatch({ type: 'HANDLE_CLEAR_VERSES' });
						collectionId
							? addBulkVerses({ userId, collectionId, bulkVerseArr, uniqueKey })
							: collectionDispatch({ type: 'ADD_BULK_VERSES_TO_CN', payload: { uniqueKey, verses: bulkVerseCNArr } });
					}
				} else {
					let verseToBeAdded;
					const editable = collectionState[value.key].collection.user_id === authState.user.id;
					if (result.draggableId.includes('verses')) verseToBeAdded = versesState[result.source.droppableId].verses[result.source.index];
					else if (result.draggableId.includes('search_result')) verseToBeAdded = searchState[result.source.droppableId][result.source.index];
					else if (result.draggableId.includes('collectionNote'))
						verseToBeAdded = collectionState[result.source.droppableId].collection.verses[result.source.index];
					else return;

					let userId = authState.user.id;
					let uniqueKey = value.key;

					let newCollectionNoteVerseData = {
						userId,
						uniqueKey,
						verseNumber: verseToBeAdded.number,
						chapterId: verseToBeAdded.chapter.id,
						verseId: verseToBeAdded.id,
						collectionId: collectionState[value.key].collection.id,
					};

					if (!collectionState[value.key].collection.id) {
						handleAddVerseToNewCollectionPane(verseToBeAdded, uniqueKey);
						handleAddVerseToNewCollectionNote(verseToBeAdded, uniqueKey);
					} else {
						if (editable) addVerseToCn(newCollectionNoteVerseData);

						if (authState && authState.user && authState.user.id) {
							// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'], upgrade: false }).emit('update_cn_list', { fromVerse: true, userId: authState.user.id });
							// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'], upgrade: false }).emit('update_verse', { verseId: verseToBeAdded.id, userId: authState.user.id });
						}
					}
				}
			}
		});
	};
	const closePane = (key, collectionId) => {
		console.log(key);
		if (collectionId) {
			const removeCollectionNotesById = data.filter(paneObj => paneObj.collectionId !== collectionId);
			//? Not sure why we were filtering collections that didnt have an id it auto closes unsaved cns if you delete a cn
			// .filter(paneObj => paneObj.collectionId !== null);
			dispatch({ type: 'RESET_PANES', payload: removeCollectionNotesById });
		} else {
			dispatch({ type: 'REMOVE_PANE', payload: key });
		}
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const toleft = panelsBlock.scrollLeft;
		setTimeout(() => {
			panelsBlock.scroll({
				left: toleft,
				behavior: 'auto',
			});
		}, 10);
	};

	const closeAllPanes = (key, collectionId) => {
		const removeCollectionNotesById = data
			.filter(paneObj => paneObj.collectionId !== collectionId)
			.filter(paneObj => paneObj.collectionId !== null)
			.filter(paneObj => paneObj.collectionId == collectionId);
		dispatch({ type: 'RESET_PANES', payload: removeCollectionNotesById });
	};
	const bulkMoveModal = async obj => {
		if (bulkMoveState.length > 0 && bulkMoveState.some(e => e.draggableId === obj.draggableId)) {
			let bulkVerseArr = [];
			let bulkVerseCNArr = [];
			let userId = authState.user.id;
			const { collectionId } = obj;
			bulkMoveState.forEach((e, i) => {
				let verseToBeAdded;

				if (e.draggableId.includes('verses')) verseToBeAdded = versesState[e.droppableId].verses[e.index];
				else if (e.draggableId.includes('search_result')) verseToBeAdded = searchState[e.droppableId][e.index];
				else if (e.draggableId.includes('collectionNote')) verseToBeAdded = collectionState[e.droppableId].collection.verses[e.index];
				else return;

				let newCollectionNoteVerseData = {
					userId,
					verseNumber: verseToBeAdded.number,
					chapterId: verseToBeAdded.chapter.id,
					verseId: verseToBeAdded.id,
					collectionId: collectionId,
				};
				bulkVerseArr.push(newCollectionNoteVerseData);
				bulkVerseCNArr.push(verseToBeAdded);
			});

			bulkMoveDispatch({ type: 'HANDLE_CLEAR_VERSES' });

			// collectionId
			// addBulkVerses({ userId, collectionId, bulkVerseArr, uniqueKey })
			// : collectionDispatch({ type: 'ADD_BULK_VERSES_TO_CN', payload: { uniqueKey, verses: bulkVerseCNArr } });
			await fetch(`${process.env.REACT_APP_BASE_URL}/collections/addBulkVerses/${userId}/${collectionId}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					bulkVerseArr,
				}),
			});
			return;
		}
	};
	/** This is a way for us to quickly disable/enable sockets */
	// const fakeSocket = {
	// 	off: pointlessString => null,
	// 	on: (pointlessString, pointlessFunction) => null,
	// 	emit: (pointlessString, pointlessObject) => null,
	// };

	const components = state.map((obj, key) => {
		switch (obj.type) {
			case 'dashboard':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => {
							return (
								<div ref={provided.innerRef} {...provided.draggableProps}>
									<Dashboard
										data={data}
										history={props.history}
										user={authState.user}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										uniqueKey={obj.key}
										closePane={closePane}
										socket={state[key].socket}
									/>
									{provided.placeholder}
								</div>
							);
						}}
					</Draggable>
				);
			case 'library':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='library-resizable'
									defaultSize={{ width: '550px', height: '100%' }}
									style={{ overflow: 'hidden' }}
									minWidth={550}
									maxWidth={700}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Library
										dragHandleProps={provided.dragHandleProps}
										user={authState.user}
										id={key}
										uniqueKey={obj.key}
										closePane={closePane}
										studyPaneOpen={obj.open}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'collectionList':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='collections-resizable'
									minWidth={550}
									maxWidth={800}
									defaultSize={{ width: '550px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<CollectionList
										user={authState.user}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										uniqueKey={obj.key}
										closePane={closePane}
										socket={state[key].socket}
										hideDone={hideDone}
										hideMine={hideMine}
										setHideDone={setHideDone}
										setHideMine={setHideMine}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'analytics':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='analytics-resizable'
									style={{ overflow: 'hidden' }}
									minWidth={350}
									maxWidth={700}
									defaultSize={{ width: '600px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Analytics
										user={authState.user}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										uniqueKey={obj.key}
										closePane={closePane}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'tags':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='tags-resizable'
									minWidth={350}
									maxWidth={700}
									defaultSize={{ width: '600px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Tags
										user={authState.user}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										uniqueKey={obj.key}
										closePane={closePane}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'search':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='search-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={700}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Search
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										display={state[key].display}
										filterTree={state[key].filterTree}
										filterTreeCategoriesAndTags={state[key].filterTreeCategoriesAndTags}
										filtersEnabled={state[key].filtersEnabled}
										resultPaneKey={state[key].resultPaneKey}
										resultsLibrary={state[key].resultsLibrary}
										searchHistory={state[key].searchHistory}
										searchText={state[key].searchText}
										searchingFromResults={state[key].searchingFromResults}
										selectedBooks={state[key].selectedBooks}
										selectedOption={state[key].selectedOption}
										tagText={state[key].tagText}
										// socket={state[key].socket}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'collectionNote':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<CollectionNote
									user={authState.user}
									dragHandleProps={provided.dragHandleProps}
									collectionId={obj.collectionId}
									shareCode={state[key].shareCode}
									collectionObj={obj}
									uniqueKey={obj.key}
									id={key}
									studyHelpInfo={obj.studyHelpInfo}
									closePane={closePane}
									socket={state[key].socket}
									bulkMoveModal={bulkMoveModal}
									hideDone={hideDone}
									hideMine={hideMine}
									setHideDone={setHideDone}
									setHideMine={setHideMine}
									text={state[key].text}
								/>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'search_result':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<SearchResult
									categories={obj.categories || null}
									tags={obj.tags || null}
									books={obj.books || null}
									searchSection={obj.searchSection}
									searchText={obj.searchText}
									uniqueKey={obj.key}
									dragHandleProps={provided.dragHandleProps}
									id={key}
									closePane={closePane}
									user={authState.user}
									socket={state[key].socket}
									volumesFilter={state[key].volumesFilter}
									bulkMoveModal={bulkMoveModal}
									hideDone={hideDone}
									hideMine={hideMine}
									setHideDone={setHideDone}
									setHideMine={setHideMine}
								/>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'verses':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Verses
									user={authState.user}
									bookId={obj.bookId}
									chapterId={obj.chapterId}
									dragHandleProps={provided.dragHandleProps}
									initialChapterId={obj.initialChapterId}
									uniqueKey={obj.key}
									id={key}
									closePane={closePane}
									socket={state[key].socket}
									bulkMoveModal={bulkMoveModal}
									hideDone={hideDone}
									hideMine={hideMine}
									setHideDone={setHideDone}
									setHideMine={setHideMine}
								/>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'todo':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='search-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={700}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Todo
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										socket={state[key].socket}
										expandedArr={state[key].expandedArr}
										showCompleted={state[key].showCompleted}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'podcasts_list':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='collections-resizable'
									minWidth={350}
									maxWidth={800}
									defaultSize={{ width: '350px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Podcasts
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										socket={state[key].socket}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'podcast':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='search-resizable-podcast'
									// style={{ lineHeight: 4.5 }}
									minWidth={820}
									maxWidth={820}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Podcast
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										socket={state[key].socket}
										podcast={state[key].podcast}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'podcastPreview':
				return (
					// <Draggable key={obj.key} draggableId={obj.key} index={key}>
					// 	{provided => (
					// 		<div ref={provided.innerRef} {...provided.draggableProps}>
					<div>
						<Resizable
							// key={key}
							className='search-resizable-podcast'
							// style={{ lineHeight: 4.5 }}
							minWidth={475}
							maxWidth={800}
							defaultSize={{ width: '415px', height: '100%' }}
							enable={{ right: true }}
							handleComponent={{
								right: (
									<FontAwesomeIcon
										color='#b2b7be'
										icon={faEllipsisV}
										style={{
											top: '50%',
											right: '7px',
											position: 'absolute',
										}}
									/>
								),
							}}
						>
							<PodcastPreview
								user={authState.user}
								uniqueKey={obj.key}
								// dragHandleProps={provided.dragHandleProps}
								id={key}
								closePane={closePane}
								link={state[key].link}
								podcast={state[key].podcast}
								episode={state[key].episode}
								preview={state[key].preview}
								socket={state[key].socket}
								linkType={state[key].linkType}
								checked={state[key].checked}
							/>
						</Resizable>
						{/* {provided.placeholder} */}
					</div>
					// 	)}
					// </Draggable>
				);
			case 'videoPlayer':
				return (
					// <Draggable key={obj.key} draggableId={obj.key} index={key}>
					// 	{provided => (
					<div>
						<Resizable
							// key={key}
							className='search-resizable-podcast'
							// style={{ lineHeight: 4.5 }}
							minWidth={475}
							maxWidth={800}
							defaultSize={{ width: '415px', height: '100%' }}
							enable={{ right: true }}
							handleComponent={{
								right: (
									<FontAwesomeIcon
										color='#b2b7be'
										icon={faEllipsisV}
										style={{
											top: '50%',
											right: '7px',
											position: 'absolute',
										}}
									/>
								),
							}}
						>
							<VideoPlayer
								user={authState.user}
								uniqueKey={obj.key}
								// dragHandleProps={provided.dragHandleProps}
								id={key}
								closePane={closePane}
								url={state[key].url}
							/>
						</Resizable>
						{/* {provided.placeholder} */}
					</div>
					// 	)}
					// </Draggable>
				);
			case 'games':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Games
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										socket={state[key].socket}
										resources={state[key].resources}
										chapter={state[key].chapter}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'image':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div>
								<Resizable
									// key={key}
									className='search-resizable-podcast'
									// style={{ lineHeight: 4.5 }}
									minWidth={800}
									maxWidth={800}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<Image
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										imageUrl={state[key].imageUrl}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'chapterResources':
				return (
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<ChapterResources
										user={authState.user}
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										socket={state[key].socket}
										resources={state[key].resources}
										chapter={state[key].chapter}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'time_skimmer':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<TimeSkimmer
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										user={authState.user}
										socket={state[key].socket}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'wordUseReport':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<WordUseReport
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										user={authState.user}
										documents={state[key].documents}
										booksString={state[key].booksString}
										volumes={state[key].volumes}
										books={state[key].books}
										socket={state[key].socket}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'chatgpt':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<ChatGPT
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										user={authState.user}
										verse={state[key].verse}
										response={state[key].response}
										question={state[key].question}
										threadId={state[key].threadId}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'authPane':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={false}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<AuthPane
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										user={authState.user}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'trainingPane':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={475}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={{ right: true }}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<TrainingPane
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										user={authState.user}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			case 'googleReviews':
				return (
					// <Draggable key={data.key} draggableId={data.key} index={key}>
					<Draggable key={obj.key} draggableId={obj.key} index={key}>
						{provided => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<Resizable
									// key={key}
									className='chapter-resources-resizable'
									// style={{ lineHeight: 4.5 }}
									minWidth={415}
									maxWidth={600}
									defaultSize={{ width: '415px', height: '100%' }}
									enable={false}
									handleComponent={{
										right: (
											<FontAwesomeIcon
												color='#b2b7be'
												icon={faEllipsisV}
												style={{
													top: '50%',
													right: '7px',
													position: 'absolute',
												}}
											/>
										),
									}}
								>
									<GoogleReviews
										uniqueKey={obj.key}
										dragHandleProps={provided.dragHandleProps}
										id={key}
										closePane={closePane}
										user={authState.user}
										verse={state[key].verse}
									/>
								</Resizable>
								{provided.placeholder}
							</div>
						)}
					</Draggable>
				);
			default:
				return {};
		}
	});

	return (
		<>
			<Menu history={props.history} closeAllPanes={closeAllPanes} />
			{/* <VerseOptions
				optionVerseId={optionVerseId}
				isOpenOptions={isOpenOptions}
				optionPaneUniqueId={optionPaneUniqueId}
				optionVerseProps={optionVerseProps}
				userId={authState.user.id}
			/> */}
			<AppContain className='panelsWrapper' key='content'>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable className='single-panel' droppableId='board' direction='horizontal' type='COLUMN'>
						{(provided, snapshot) => (
							<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
								<ReactCSSTransitionGroup
									component='div'
									className='draggable-wrapper'
									transitionName='component'
									transitionEnterTimeout={300}
									transitionLeaveTimeout={300}
									transitionAppear={true}
									transitionAppearTimeout={300}
								>
									{components}
								</ReactCSSTransitionGroup>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</AppContain>
		</>
	);
};
