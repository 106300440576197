import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faCheckSquare } from '@fortawesome/fontawesome-free-solid/';
import { faSquare } from '@fortawesome/fontawesome-free-regular';
import Loading from '../Helpers/Loading';

import _ from 'lodash';

import { Scrollbars } from 'react-custom-scrollbars';

import { NavContext } from '../../ContextProviders/NavProvider';
import { StudyHelpsContext } from '../../ContextProviders/StudyHelpsProvider';

const StudyHelpsContainer = styled.div`
	// display: flex;
	// flex-direction: column;
`;

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchInputContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
`;

const LibraryText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
`;

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
	font-size: 15.5px;
`;

const SearchIconWrapper = styled.button`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;

const FilterSearchContainer = styled.div`
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	place-content: stretch space-between;
	padding-left: 25px;
	padding-right: 25px;
`;

const RadioButtonContainer = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: flex-start;
	height: 30px;
	width: 50%;
`;

const RadioLabel = styled.label`
	font-weight: 500;
	margin-bottom: 0%;
	padding-bottom: 20px;
	margin-left: 4px;
	color: #6a7583;
	font-size: 17px;
	line-height: 25px;
	cursor: pointer;
	transition: color 0.15s linear;
	&:hover {
		color: #5e6773;
	}
`;

const SectionsHeader = styled.div`
	margin-top: 56px;
	display: flow-root;
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 18px;
	color: #6a7583;
`;

const SectionsContainer = styled.div`
	display: flow-root;
	padding-left: 25px;
`;

const SectionsList = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const SectionLetter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	font-weight: 600;

	background-color: #eaeaea;
	color: #6a7583;
	width: 30px;
	height: 30px;
	// padding: 16px;
	padding: 18px;
	margin: 4px 8px 4px 0;

	cursor: pointer;
	&:hover {
		background-color: #eaeaea90;
	}
`;

const StudyResults = styled.div``;

const StudyList = styled.div`
	zindex: 10000;
	position: relative;
	// overflow: auto;
	display: flow-root;
	// height: calc(100vh - 550px);
	// height: calc(100vh - 650px);

	margin-left: 4px;
`;

const StudyTopic = styled.div`
	display: flex;
	align-items: center;
	margin-left: 25px;
`;

const StudyTopicTitle = styled.div`
	cursor: pointer;
	font-weight: 600;
	&:hover {
		background-color: #eaeaea90;
	}
`;

const SingleTopicNav = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const SingleTopicContainer = styled.div`
	margin-top: -50px;
	padding-left: 25px;
	padding-right: 10px;
`;

const TopicHeader = styled.div`
	// display: flex;
	// justify-content: space-between;

	display: grid;
	grid-template-columns: 1fr 100px;
	align-items: center;
	margin: 8px 16px 8px 0;
`;

const TopicCnButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 5px;
	line-height: 10px;
	// width: 40%;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	// height: 80%;
	height: 35px;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
`;

const TopicReferences = styled.div`
	// overflow: scroll;
	// height: calc(100vh - 250px);
	margin: 8px 0;
	font-size: 15px;
	color: #393f47;
	padding-right: 12px;
`;

const TopicReference = styled.div`
	margin: 8px 0;
	font-size: 15px;
	color: #393f47;
	padding-right: 12px;
`;

const StudiedBanner = styled.div`
	position: relative;
	padding: 8px 0;
	background: #fcb447;
	display: flex;
	margin-top: 24px;
	width: 100%;
`;

const TopicAreaContainer = styled.div`
	min-width: 70px;
`;

const TopicWordWrapper = styled.span`
	color: blue;
	cursor: pointer;
	margin-right: 4px;

	&:hover {
		text-decoration: underline;
	}
`;

const BannerSection = styled.div`
	width: 70px;
	color: #ffffff;
	margin-left: 16px;
	font-size: 15px;
`;

const studyOptions = [
	{ name: 'Topical Guide', value: 'topicalGuide' },
	{ name: 'Bible Dictionary', value: 'bibleDictionary' },
	{ name: 'Index to TC', value: 'tripleIndex' },
	{ name: 'All', value: 'all' },
];

const alphaList = 'abcdefghijklmnopqrstuvwxyz'.split('');

const StudyHelps = props => {
	const { handleOpenVerses, handleAddNewCollectionNote, handleOpenStudyTopic, handleUpdateStudyTopic } = useContext(NavContext);
	const { studyHelpsState, studyHelpsDispatch } = useContext(StudyHelpsContext);
	const [selectedOption, setSelectedOption] = useState(props.selectedOption || 'topicalGuide');
	const [selectedLetter, setSelectedLetter] = useState(props.selectedLetter ? props.selectedLetter : 'a');
	const [topicalGuide, setTopicalGuide] = useState(studyHelpsState.topicalGuide);
	const [bibleDictionary, setBibleDictionary] = useState(studyHelpsState.bibleDictionary);
	const [tripleIndex, setTripleIndex] = useState(studyHelpsState.tripleIndex);
	const [selectedTopic, setSelectedTopic] = useState(props.selectedTopic && props.selectedTopic !== 'none' ? props.selectedTopic : null);
	const [loading, setLoading] = useState(false);
	const [previousTopics, setPreviousTopics] = useState([]);
	const [previousIndex, setPreviousIndex] = useState(0);
	const handleSubmit = e => {
		e.preventDefault();
	};
	// useEffect(() => console.log(`'Pane ${props.id}'s selectedTopic: '`, { selectedTopic, propsTopic: props.selectedTopic }), [props.selectedTopic])
	useEffect(() => {
		handleUpdateStudyTopic({
			paneKey: props.uniqueKey,
			selectedTopic: selectedTopic,
			selectedOption: selectedOption,
			selectedLetter: selectedLetter,
		});
	}, [selectedOption, selectedLetter, selectedTopic]);

	useEffect(() => {
		setTopicalGuide(studyHelpsState.topicalGuide);
		setBibleDictionary(studyHelpsState.bibleDictionary);
		setTripleIndex(studyHelpsState.tripleIndex);
	}, [studyHelpsState]);
	// console.log('pane:', props.id, {
	// 	tripleIndex,
	// })
	useEffect(() => {
		setLoading(true);

		// Whenever the selectedLetter is changed this hook will run. It will check each of the study helps and if
		// the items starting with the letter haven't been retrieved from the db yet, they will be. Once they're
		// retrieved they'll be added to their existing study help array. This way there won't be duplicate requests
		// for already retrieved data.
		if (selectedOption === 'topicalGuide' && !topicalGuide.length) {
			fetchTopicalGuideItems();
		} else setLoading(false);
		if (selectedOption === 'bibleDictionary' && !bibleDictionary.length) {
			fetchBibleDictionary();
		} else setLoading(false);
		if (selectedOption === 'tripleIndex' && !tripleIndex.length) {
			fetchTripleIndex();
		} else setLoading(false);
		if (selectedOption === 'all') {
			!topicalGuide.length && fetchTopicalGuideItems();
			!bibleDictionary.length && fetchBibleDictionary();
			!tripleIndex.length && fetchTripleIndex();
		} else setLoading(false);
		// Whenever the selectedLetter is changed this hook will run. It will check each of the study helps and if
		// the items starting with the letter haven't been retrieved from the db yet, they will be. Once they're
		// retrieved they'll be added to their existing study help array. This way there won't be duplicate requests
		// for already retrieved data.

		// setLoading(true);
		// if (!topicalGuide.some(({ title }) => title.toLowerCase().startsWith(selectedLetter.toLowerCase()))) {
		// 	const fetchTopicalGuideItems = async () => {
		// 		const topicalGuideItems = await fetch(
		// 			`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuide/${props.user.id}/${selectedLetter}`
		// 		).then(res => res.json()).catch(err => console.log('Topical err', err));

		// 		if (topicalGuideItems && topicalGuideItems.length) {
		// 			studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide: [...topicalGuide, ...topicalGuideItems] } });
		// 			setTopicalGuide([...topicalGuide, ...topicalGuideItems]);
		// 			setLoading(false);
		// 		}
		// 	};
		// 	fetchTopicalGuideItems();
		// } else {
		// 	setLoading(false);
		// }

		// if (!bibleDictionary.some(({ title }) => title.toLowerCase().startsWith(selectedLetter.toLowerCase()))) {
		// 	const fetchBibleDictionary = async () => {
		// 		const bibleDictionaryItems = await fetch(
		// 			`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionary/${props.user.id}/${selectedLetter}`
		// 		).then(res => res.json()).catch(err => console.log(props.id, 'BD:', err));

		// 		if (bibleDictionaryItems && bibleDictionaryItems.length) {
		// 			studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary: [...bibleDictionary, ...bibleDictionaryItems] } });
		// 			setBibleDictionary([...bibleDictionary, ...bibleDictionaryItems]);
		// 			setLoading(false);
		// 		}
		// 	};

		// 	fetchBibleDictionary();
		// } else {
		// 	setLoading(false);
		// }

		// if (!tripleIndex.some(({ title }) => title.toLowerCase().startsWith(selectedLetter.toLowerCase()))) {
		// 	const fetchTripleIndex = async () => {
		// 		const tripleIndexItems = await fetch(
		// 			`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndex/${props.user.id}/${selectedLetter}`
		// 		).then(res => res.json()).catch(err => console.log('Study helps pane', props.id, 'getTripleIndex:', err));

		// 		if (tripleIndexItems && tripleIndexItems.length) {
		// 			studyHelpsDispatch({ type: 'SET_TRIPLE_INDEX', payload: { tripleIndex: [...tripleIndex, ...tripleIndexItems] } });
		// 			setTripleIndex([...tripleIndex, ...tripleIndexItems]);
		// 			setLoading(false);
		// 		}
		// 	};
		// 	// This is commented out for deployment for now until the Triple Index is ready to go
		// 	fetchTripleIndex();

		// } else {
		// 	setLoading(false);
		// }
	}, [selectedOption]);

	function removeDuplicates(array, key) {
		let lookup = new Set();
		return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
	}
	const handleGetReleventInfo = async (id, index, combinedIndex, overallIndex, title, studyHelpName, seeAlso) => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 500;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;

		var newwidth = children.offsetWidth;
		var nwidth = newwidth;
		nwidth = oldwidth;
		var toscrollright = nwidth - rightposition;
		setTimeout(() => {
			if (toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 500);
		//Makes query for Bible Dictionary
		if (studyHelpName === 'BD') {
			const bibleDictionaryItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionaryTitleInfo/${props.user.id}/${id}`, {
				title,
			})
				.then(res => res.json())
				.catch(err => console.log(props.id, 'BD:', err));

			const { references, text } = bibleDictionaryItems;

			bibleDictionary[index].text = text;
			bibleDictionary[index].references = removeDuplicates(references, 'verseId');
			handleOpenStudyTopic({
				originPaneKey: props.uniqueKey,
				open: true,
				selectedOption: 'bibleDictionary',
				selectedLetter: title.slice(0, 1).toLowerCase(),
				selectedTopic: { index, combinedIndex, overallIndex, title, studyHelp: studyHelpName },
			});
		}
		//Makes query for Topical guide
		if (studyHelpName === 'TG') {
			const topicalGuideItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuideTitleInfo/${props.user.id}/${id}`, { title })
				.then(res => res.json())
				.catch(err => console.log(props.id, 'TG:', err));

			topicalGuide[index].references = removeDuplicates(topicalGuideItems, 'verseId');
			handleOpenStudyTopic({
				originPaneKey: props.uniqueKey,
				open: true,
				selectedOption: 'topicalGuide',
				selectedLetter: title.slice(0, 1).toLowerCase(),
				selectedTopic: { index, combinedIndex, overallIndex, title, seeAlso, studyHelp: studyHelpName },
			});
		}

		if (studyHelpName === 'TC') {
			// console.log(`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndexTitleInfo/${props.user.id}/${id}`, { title }``)
			const tripleIndexItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndexTitleInfo/${props.user.id}/${id}`, { title })
				.then(res => res.json())
				.catch(err => console.log(props.id, 'TC:', err));
			const { references, text } = tripleIndexItems;
			tripleIndex[index].text = text;
			tripleIndex[index].references = removeDuplicates(references, 'verseId');
			handleOpenStudyTopic({
				originPaneKey: props.uniqueKey,
				open: true,
				selectedOption: 'tripleIndex',
				selectedLetter: title.slice(0, 1).toLowerCase(),
				selectedTopic: { index, combinedIndex, overallIndex, title, seeAlso, studyHelp: studyHelpName },
			});
		}
	};

	/** Opens a reading pane from the given verse id */
	const openReadingPane = (bookId, chapterId, verseNumber) => {
		handleOpenVerses({ bookId, chapterId, verseNumber, originPaneKey: props.uniqueKey, proUser: props.proUser, initialChapterId: chapterId });
	};

	const fetchTopicalGuideItems = async () => {
		const topicalGuideItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuide/${props.user.id}/${selectedLetter}`)
			.then(res => res.json())
			.catch(err => console.log('Topical err', err));

		if (topicalGuideItems && topicalGuideItems.length) {
			studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide: [...topicalGuide, ...topicalGuideItems] } });
			setTopicalGuide([...topicalGuide, ...topicalGuideItems]);
			setLoading(false);
		}
	};
	const fetchBibleDictionary = async () => {
		const bibleDictionaryItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionary/${props.user.id}/${selectedLetter}`)
			.then(res => res.json())
			.catch(err => console.log(props.id, 'BD:', err));

		if (bibleDictionaryItems && bibleDictionaryItems.length) {
			studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary: [...bibleDictionary, ...bibleDictionaryItems] } });
			setBibleDictionary([...bibleDictionary, ...bibleDictionaryItems]);
			setLoading(false);
		}
	};
	const fetchTripleIndex = async () => {
		const tripleIndexItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndex/${props.user.id}/${selectedLetter}`)
			.then(res => res.json())
			.catch(err => console.log('Study helps pane', props.id, 'getTripleIndex:', err));

		if (tripleIndexItems && tripleIndexItems.length) {
			studyHelpsDispatch({ type: 'SET_TRIPLE_INDEX', payload: { tripleIndex: [...tripleIndex, ...tripleIndexItems] } });
			setTripleIndex([...tripleIndex, ...tripleIndexItems]);
			setLoading(false);
		}
	};
	/** Creates a new Collection Note with all of the topic's verses */
	const createTopicCn = async () => {
		try {
			let currentStudyHelp =
				selectedOption === 'topicalGuide'
					? topicalGuide
					: selectedOption === 'bibleDictionary'
					? bibleDictionary
					: selectedOption === 'tripleIndex'
					? tripleIndex
					: [...topicalGuide, ...bibleDictionary, ...tripleIndex].sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));

			const versesString = currentStudyHelp[selectedTopic.index].references.map(({ verseId }) => verseId).join('-');
			let verses = await fetch(`${process.env.REACT_APP_BASE_URL}/verses/getVersesByIds/${props.user.id}/${versesString}`)
				.then(res => res.json())
				.catch(err => console.log('Get verse by id:', err));
			if (verses && verses.stack) verses = [];
			const BDTitleText = currentStudyHelp.filter(array => array.title === selectedTopic.title);

			handleAddNewCollectionNote(verses, props.uniqueKey, {
				studyHelpName: selectedTopic.studyHelp === 'TG' ? 'Topical Guide' : selectedTopic.studyHelp === 'TC' ? 'Index to TC' : 'Bible Dictionary',
				studyHelpTopic: selectedTopic.title,
			});

			// handleAddNewCollectionNote(verses, props.uniqueKey, { studyHelpName: 'Topical Guide', studyHelpTopic: selectedTopic.title });
		} catch (error) {
			console.log('Error retrieving verses by their ids:', error);
		}
	};
	const handlePrevNext = selection => {
		if (selection === 'prev') {
			const exists = previousTopics.some(({ index }) => index === selectedTopic.index);
			if (!exists) {
				setPreviousTopics([...previousTopics, selectedTopic]);
			}
			previousIndex > 0 && setPreviousIndex(previousIndex - 1);
			changeTopic(
				previousTopics[previousIndex - 1].index,
				selectedTopic.studyHelp === 'TG' ? topicalGuide : tripleIndex,
				previousTopics[previousIndex - 1].index
			);
		}
		if (selection === 'next') {
			previousIndex < previousTopics.length && setPreviousIndex(previousIndex + 1);
			changeTopic(
				previousTopics[previousIndex + 1].index,
				selectedTopic.studyHelp === 'TG' ? topicalGuide : tripleIndex,
				previousTopics[previousIndex + 1].index
			);
		}
	};
	/** This component creates the 'See Also' topics from the retrieved db string
	 *  and provides a way for a user to navigate to those topics. */
	const SeeAlso = subProps => {
		const additionalTopics = selectedTopic.seeAlso
			? selectedTopic.seeAlso.slice(selectedTopic.seeAlso.toLowerCase().startsWith('see also') ? 9 : 4).split('; ')
			: [];

		return (
			<div style={{ marginVertical: 16, paddingBottom: 8, color: '#6d7886', display: 'flex', flexWrap: 'wrap' }}>
				{additionalTopics && additionalTopics.length && additionalTopics[0] ? <span style={{ marginRight: 4 }}>See also</span> : null}
				{additionalTopics.map((topicWord, index) => (
					<div key={index}>
						{topicWord ? (
							<TopicWordWrapper
								key={index}
								onClick={async () => {
									const exists = previousTopics.some(({ index }) => index === selectedTopic.index);
									if (!exists) {
										setPreviousTopics([...previousTopics, selectedTopic]);
									}
									if (exists) {
										let index = previousTopics.findIndex(({ index }) => index === selectedTopic.index);
										let splicedPreviousTopics = previousTopics;
										splicedPreviousTopics.splice(index + 1, previousTopics.length);
										setPreviousTopics(splicedPreviousTopics);
									}
									setPreviousIndex(previousIndex + 1);
									let topicRefWord = topicWord;

									// There are a few occasions where the type of word is specified (eg. verb) so we remove
									// that here when navigating to that word
									// if (topicWord.includes('[')) {
									// 	topicRefWord = topicWord.slice(0, topicWord.indexOf(' ['));
									// }

									let isBdRef = false;
									let isTcRef = false;
									let isTcTgRef = false;

									if (topicRefWord.includes('BD')) {
										isBdRef = true;
										topicRefWord = topicRefWord.slice(3);
										await fetchBibleDictionary();
									} else if (selectedTopic.studyHelp === 'TC') {
										isTcRef = true;
									}

									if (topicRefWord.startsWith('TG') && selectedTopic.studyHelp === 'TC') {
										isTcTgRef = true;
										topicRefWord = topicRefWord.slice(3);
										isTcRef = false;
									}
									if (topicWord.includes('[') && !isTcRef) {
										topicRefWord = topicWord.slice(0, topicWord.indexOf(' ['));
									}
									// TODO Finish the isTcRef logic
									let newTopicIndex = (isBdRef ? bibleDictionary : isTcRef ? tripleIndex : topicalGuide).findIndex(
										({ title }) => title === topicRefWord
									);
									if (newTopicIndex === -1) {
										newTopicIndex = (isBdRef ? bibleDictionary : isTcRef ? tripleIndex : topicalGuide).findIndex(({ title }) =>
											title.startsWith(topicRefWord)
										);
									}

									const { title, seeAlso } = selectedTopic;
									if (newTopicIndex !== -1) {
										if (!isBdRef && !isTcRef) {
											const topicalGuideItems = await fetch(
												`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuideTitleInfo/${props.user.id}/${topicalGuide[newTopicIndex].id}`,
												{ title }
											)
												.then(res => res.json())
												.catch(err => console.log(props.id, 'TG:', err));
											topicalGuide[newTopicIndex].references = topicalGuideItems;
											if (isTcTgRef) {
												studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide: [...topicalGuide] } });
												setTopicalGuide([...topicalGuide]);
												handleOpenStudyTopic({
													originPaneKey: props.uniqueKey,
													open: true,
													selectedOption: 'topicalGuide',
													selectedTopic: {
														index: newTopicIndex,
														combinedIndex: newTopicIndex,
														overallIndex: newTopicIndex,
														title: topicRefWord,
														studyHelp: 'TG',
														seeAlso: seeAlso,
													},
												});
											} else changeTopic(newTopicIndex - selectedTopic.index, topicalGuide, newTopicIndex);
										}

										if (isBdRef && !isTcRef) {
											const bibleDictionaryItems = await fetch(
												`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionaryTitleInfo/${props.user.id}/${bibleDictionary[newTopicIndex].id}`,
												{ title }
											)
												.then(res => res.json())
												.catch(err => console.log(props.id, 'BD:', err));

											const { references, text } = bibleDictionaryItems;

											bibleDictionary[newTopicIndex].text = text;
											bibleDictionary[newTopicIndex].references = references;
											handleOpenStudyTopic({
												originPaneKey: props.uniqueKey,
												open: true,
												selectedOption: 'bibleDictionary',
												selectedTopic: {
													index: newTopicIndex,
													combinedIndex: newTopicIndex,
													overallIndex: newTopicIndex,
													title: title,
													studyHelp: 'BD',
												},
											});
										}
										if (isTcRef && !isBdRef) {
											const tripleIndexItems = await fetch(
												`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndexTitleInfo/${props.user.id}/${tripleIndex[newTopicIndex].id}`,
												{ title }
											)
												.then(res => res.json())
												.catch(err => console.log(props.id, 'TC:', err));
											tripleIndex[newTopicIndex].references = tripleIndexItems.references;
											changeTopic(newTopicIndex - selectedTopic.index, tripleIndex, newTopicIndex);
										}
									} else {
										if (isBdRef && !isTcRef) {
											const bibleDictionaryItems = await fetch(
												`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionary/${props.user.id}/${selectedLetter}`
											)
												.then(res => res.json())
												.catch(err => console.log(props.id, 'BD:', err));

											if (bibleDictionaryItems && bibleDictionaryItems.length) {
												newTopicIndex = bibleDictionaryItems.findIndex(({ title }) => title === topicRefWord);
												if (newTopicIndex === -1) {
													newTopicIndex = bibleDictionaryItems.findIndex(({ title }) => title.startsWith(topicRefWord));
												}

												const bibleDictionaryTitleInfo = await fetch(
													`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionaryTitleInfo/${props.user.id}/${bibleDictionaryItems[newTopicIndex].id}`,
													{ title }
												)
													.then(res => res.json())
													.catch(err => console.log(props.id, 'BD:', err));

												const { references, text } = bibleDictionaryTitleInfo;

												bibleDictionaryItems[newTopicIndex].text = text;
												bibleDictionaryItems[newTopicIndex].references = references;
												studyHelpsDispatch({
													type: 'SET_BIBLE_DICTIONARY',
													payload: { bibleDictionary: [...bibleDictionary, ...bibleDictionaryItems] },
												});
												setBibleDictionary([...bibleDictionary, ...bibleDictionaryItems]);
												handleOpenStudyTopic({
													originPaneKey: props.uniqueKey,
													open: true,
													selectedOption: 'bibleDictionary',
													selectedTopic: {
														index: newTopicIndex,
														combinedIndex: newTopicIndex,
														overallIndex: newTopicIndex,
														title: topicRefWord,
														studyHelp: 'BD',
													},
												});
											}
										}
										// let startingLetter = topicRefWord.slice(0, 1);
										// setSelectedLetter(startingLetter.toLowerCase())
										// // setSelectedLetter(startingLetter)
										// // const studyGuideItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuide/${props.user.id}/${startingLetter}`)
										// const studyGuideItems = await fetch(
										// 	`${process.env.REACT_APP_BASE_URL}/volumes/${isBdRef ? 'getBibleDictionary' : isTcRef ? 'getTripleIndex' : 'getTopicalGuide'
										// 	}/${props.user.id}/${startingLetter}`
										// ).then(res => res.json()).catch(err => console.log('Study guide', err));

										// // This was in progress but never finished. This part would essentially allow
										// // a user to navigate to a referenced BD topic from the TG.
										// if (isBdRef && !isTcRef) {
										// 	let newBibleDictionary = [...bibleDictionary, ...studyGuideItems];
										// 	newTopicIndex = newBibleDictionary.findIndex(({ title }) => title === topicRefWord);

										// 	const bibleDictionaryItems = await fetch(
										// 		`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionaryTitleInfo/${props.user.id}/${bibleDictionary[newTopicIndex].id}`, { title }
										// 	).then(res => res.json()).catch(err => console.log(props.id, 'BD:', err));

										// 	const { references, text } = bibleDictionaryItems

										// 	newBibleDictionary[newTopicIndex].text = text
										// 	newBibleDictionary[newTopicIndex].references = references

										// 	setBibleDictionary(newBibleDictionary);
										// 	studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary: newBibleDictionary } });
										// 	// This finds the correct BD title and slices it to only show the title without 'BD'
										// 	const BDTopicWord = topicRefWord

										// 	//Find the array in the BD that correlates with the right title
										// 	const titleObj = bibleDictionary.filter(array => array.title === BDTopicWord);

										// 	// Here we find the right id for the BD content to pull the correct information
										// 	const topicId = titleObj[0].id - 1;
										// 	// This opens a new pane with the correct information when clicking 'BD 'topic''

										// 	//*Commented out this out. I wasn't sure why we were trying to the change the topic on the parent component.
										// 	// This runs if there isn't a direct match, and it checks to see if there's a topic
										// 	// with the word containing it
										// 	// if (newTopicIndex === -1) {
										// 	// 	newTopicIndex = newBibleDictionary.findIndex(({ title }) =>
										// 	// 		title.toLowerCase().includes(topicRefWord.toLowerCase())
										// 	// 	);

										// 	// 	// If there still isn't a topic found at this point return
										// 	// 	if (newTopicIndex === -1) return;
										// 	// }
										// 	// changeTopic(newTopicIndex - selectedTopic.index, newBibleDictionary);
										// } else if (!isBdRef && isTcRef) {
										// 	console.log('HITTING -1')
										// } else {
										// 	let newTopicalGuide = [...topicalGuide, ...studyGuideItems];

										// 	newTopicIndex = newTopicalGuide.findIndex(({ title }) => title === topicRefWord);
										// 	if (newTopicIndex === -1) {
										// 		newTopicIndex = newTopicalGuide.findIndex(({ title }) =>
										// 			title.startsWith(`${topicRefWord}, `)
										// 		);
										// 	}

										// 	console.log(newTopicalGuide[newTopicIndex].title)
										// 	const topicalGuideItems = await fetch(
										// 		`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuideTitleInfo/${props.user.id}/${newTopicalGuide[newTopicIndex].id}`, { title }
										// 	).then(res => res.json()).catch(err => console.log(props.id, 'BD:', err));

										// 	newTopicalGuide[newTopicIndex].references = topicalGuideItems

										// 	setTopicalGuide(newTopicalGuide);
										// 	studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide: newTopicalGuide } });

										// 	// This runs if there isn't a direct match, and it checks to see if there's a topic
										// 	// with the word containing it
										// 	handleOpenStudyTopic({
										// 		originPaneKey: props.uniqueKey,
										// 		open: true,
										// 		selectedOption: 'topicalGuide',
										// 		selectedTopic: {
										// 			index: newTopicIndex,
										// 			combinedIndex: newTopicIndex,
										// 			overallIndex: newTopicIndex,
										// 			title: newTopicalGuide[newTopicIndex].title,
										// 			seeAlso: newTopicalGuide[newTopicIndex].seeAlso,
										// 			studyHelp: 'TG',
										// 		},
										// 	});
										// 	// changeTopic(newTopicIndex - selectedTopic.index, newTopicalGuide, newTopicIndex);
										// }
									}
								}}
							>
								{topicWord};
							</TopicWordWrapper>
						) : null}
					</div>
				))}
			</div>
		);
	};

	/** This component is what is rendered when a topic is selected */
	const SingleTopicView = subProps => {
		const sortVerses = (verses = []) => {
			verses = _.sortBy(
				verses,
				[verse => verse.volumeId, verse => verse.bookOrder, verse => verse.chapterOrder, verse => verse.verseNumber],
				['asc', 'asc', 'asc', 'asc']
			);
			return verses;
		};

		// Another lovely ternary
		let currentStudyHelp =
			selectedOption === 'topicalGuide'
				? topicalGuide
				: selectedOption === 'bibleDictionary'
				? bibleDictionary
				: selectedOption === 'tripleIndex'
				? tripleIndex
				: [...topicalGuide, ...bibleDictionary, ...tripleIndex].sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));

		if (selectedOption === 'all')
			currentStudyHelp = currentStudyHelp.filter(({ title }) => title.toLowerCase().startsWith(selectedLetter.toLowerCase()));

		let verseCount;
		if (
			currentStudyHelp &&
			currentStudyHelp[(selectedTopic && selectedTopic.combinedIndex) || (selectedTopic && selectedTopic.index)] &&
			currentStudyHelp[(selectedTopic && selectedTopic.combinedIndex) || (selectedTopic && selectedTopic.index)].references
		) {
			verseCount = removeDuplicates(currentStudyHelp[selectedTopic.combinedIndex || selectedTopic.index].references, 'verseId').length;
		}

		return (
			<SingleTopicContainer>
				<div style={{ padding: '5px' }}>{verseCount} Results</div>

				<Scrollbars autoHide style={{ height: 'calc(100vh - 245px)' }}>
					<TopicHeader>
						<div style={{ fontWeight: 500, fontSize: 20, color: '#6d7886', marginRight: 8 }}>{selectedTopic.title}</div>
						<TopicCnButton onClick={() => createTopicCn()}>Create CN</TopicCnButton>
					</TopicHeader>

					<SeeAlso />

					<TopicReferences>
						{/* This || inside the key brackets basically is so that when combined index exists it uses it
                which is just for viewing all study helps together, and if it's not there it just goes w the
                study help's local index */}

						{/* If StudyHelp is BD, this returns the dictionary defintion */}
						{currentStudyHelp[selectedTopic.combinedIndex].studyHelp === 'BD'
							? currentStudyHelp[selectedTopic.combinedIndex].text.map((definition, i) => (
									<TopicReference key={i}>{definition.text}</TopicReference>
							  ))
							: null}

						{currentStudyHelp[selectedTopic.combinedIndex || selectedTopic.index]
							? sortVerses(currentStudyHelp[selectedTopic.combinedIndex || selectedTopic.index].references).map(
									({ verseText, bookTitle, chapterOrder, verseNumber, bookId, chapterId }, index) => (
										<TopicReference key={index}>
											<span
												style={{ cursor: 'pointer', color: '#324599', marginRight: 8, fontWeight: 600 }}
												onClick={() => openReadingPane(bookId, chapterId, verseNumber)}
											>
												{bookTitle} {chapterOrder}:{verseNumber}
											</span>
											{verseText}
										</TopicReference>
									)
							  )
							: null}
					</TopicReferences>
				</Scrollbars>
			</SingleTopicContainer>
		);
	};

	/**
	 * Changes the selected topic based by the index offset amount (right now only -1 or +1 used for prev/next)
	 *
	 * @param offset  how many steps right or left you want to navigate in the study helps array
	 * @param newTopicalGuide  defaults to the topicalGuide state variable, but any study help array can be passed here
	 * */
	const changeTopic = async (offset, newTopicalGuide = topicalGuide, newIndex) => {
		const currentTopicIndex = selectedTopic.index;

		// Ensure attempted navigation is not exceeding the length of the array or going below a 0 index
		// if ((currentTopicIndex || offset > 0) && currentTopicIndex !== -1 && (currentTopicIndex !== newTopicalGuide.length - 1 || offset < 0) && !newIndex) {
		// 	console.log('hitting wrong logic')
		// 	// Filters the study help array by selected letter
		// 	// let filteredNewGuide = newTopicalGuide.filter(({ title }) => title.toLowerCase().startsWith(selectedLetter.toLowerCase()));
		// 	// // Creates a variable for the new topic created by the filtered array and the index + offset
		// 	// let newTopic = filteredNewGuide[selectedTopic.combinedIndex + offset];

		// 	// If there isn't a value for that calculated index, use the same index but for the
		// 	// non-filtered study helps array
		// 	let newTopic = newTopicalGuide[selectedTopic.combinedIndex + offset];
		// 	let newTopicIndex = (newTopic.studyHelp === 'BD' ? bibleDictionary : newTopic.studyHelp === 'TC' ? tripleIndex : topicalGuide).findIndex(
		// 		({ title }) => title === newTopic.title
		// 	);

		// 	const { title, id } = newTopic
		// 	const relevantInfo = await fetch(
		// 		`${process.env.REACT_APP_BASE_URL}/volumes/${newTopic.studyHelp === 'BD' ? 'getBibleDictionaryTitleInfo' : newTopic.studyHelp === 'TC' ? 'getTripleIndexTitleInfo' : 'getTopicalGuideTitleInfo'}/${props.user.id}/${id}`, { title }
		// 	).then(res => res.json()).catch(err => console.log(props.id, 'Change topics query error: ', err));

		// 	if (newTopic.studyHelp === 'BD') {
		// 		const { text, references } = relevantInfo
		// 		bibleDictionary[newTopicIndex].text = text
		// 		bibleDictionary[newTopicIndex].references = references
		// 	}
		// 	if (newTopic.studyHelp === 'TG') {
		// 		topicalGuide[newTopicIndex].references = relevantInfo
		// 	}
		// 	if (newTopic.studyHelp === 'TC') {
		// 		tripleIndex[newTopicIndex].references = relevantInfo
		// 	}
		// 	// Created the object that will be the new value for the selectedTopic state variable
		// 	let newSelectedTopic = {
		// 		title: newTopic.title,
		// 		seeAlso: newTopic.seeAlso,
		// 		index: selectedTopic.index + offset,
		// 		combinedIndex: selectedTopic.combinedIndex + offset,
		// 		studyHelp: newTopic.studyHelp,
		// 		references: newTopic.references,
		// 	};

		// 	setSelectedTopic(newSelectedTopic);
		// 	handleUpdateStudyTopic({ paneKey: props.uniqueKey, selectedTopic: newSelectedTopic, open: true });
		// }

		//this runs outside of the previous and next buttons so the wrong title isnt displayed
		// if (newIndex) {
		let newTopic = newTopicalGuide[newIndex];
		let newSelectedTopic = {
			title: newTopic.title,
			seeAlso: newTopic.seeAlso,
			index: newIndex,
			combinedIndex: newIndex,
			studyHelp: newTopic.studyHelp,
			references: newTopic.references,
		};
		setSelectedTopic(newSelectedTopic);
		handleUpdateStudyTopic({ paneKey: props.uniqueKey, selectedTopic: newSelectedTopic, open: true });
		// }
	};

	/** Toggles the studied status of a study help in the database */
	const toggleStudiedStatus = async (studiedId, studied, studyHelp = 'TG') => {
		/** This object will contain the data necessary to make the correct studied toggle api call */
		let studyHelpFetchInfo;

		if (studyHelp === 'TG') {
			studyHelpFetchInfo = { apiRouteName: 'toggleTopicalGuideStudied', studyHelpKey: 'tgId' };
		} else if (studyHelp === 'BD') {
			studyHelpFetchInfo = { apiRouteName: 'toggleBibleDictionaryStudied', studyHelpKey: 'bdId' };
		} else if (studyHelp === 'TC') {
			studyHelpFetchInfo = { apiRouteName: 'toggleTripleIndexStudied', studyHelpKey: 'tcId' };
		} else return;

		// Send POST req to db to update the studied status of the given topic
		await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/${studyHelpFetchInfo.apiRouteName}`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ userId: props.user.id, [studyHelpFetchInfo.studyHelpKey]: studiedId, studied }),
		}).then(res => res.json());
	};

	const StudyTopicSection = subProps => {
		// In case this destructuring is confusing for the combined and overall index, it just
		// defaults to the index value if their isn't a passed value.
		let { title, seeAlso, index, combinedIndex = index, overallIndex = index, id, studyHelpName } = subProps;

		return (
			<StudyTopic key={index}>
				<TopicAreaContainer style={{ width: 50 }}>
					<FontAwesomeIcon
						size='lg'
						style={{ marginLeft: '8px', cursor: 'pointer' }}
						icon={
							studyHelpName === 'TG'
								? topicalGuide.find(({ id: idd }) => idd === id).studied
									? faCheckSquare
									: faSquare
								: studyHelpName === 'BD'
								? bibleDictionary.find(({ id: idd }) => idd === id).studied
									? faCheckSquare
									: faSquare
								: studyHelpName === 'TC'
								? tripleIndex.find(({ id: idd }) => idd === id).studied
									? faCheckSquare
									: faSquare
								: null
						}
						onClick={() => {
							// This on click will check to see which study help was clicked, then it will send
							// a POST request to the api to toggle its studied status. Then it creates a copy of the given
							// study help and modifies that copy by updating the current topic's studied status. Then
							// it sets that modified copy to the state variable. Note that I did it through copying because
							// state variables are immutable and in order to modify them a copy should be created and modified.

							if (studyHelpName === 'TG') {
								toggleStudiedStatus(id, !topicalGuide[index].studied, 'TG');

								let topicalGuideCopy = [...topicalGuide];
								topicalGuideCopy[index].studied = !topicalGuide[index].studied;
								setTopicalGuide(topicalGuideCopy);
								studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide: topicalGuideCopy } });
							} else if (studyHelpName === 'BD') {
								toggleStudiedStatus(id, !bibleDictionary[index].studied, 'BD');
								let bibleDictionaryCopy = [...bibleDictionary];
								bibleDictionaryCopy[index].studied = !bibleDictionary[index].studied;
								setBibleDictionary(bibleDictionaryCopy);
								studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary: bibleDictionaryCopy } });
							} else if (studyHelpName === 'TC') {
								toggleStudiedStatus(id, !tripleIndex[index].studied, 'TC');

								let tripleIndexCopy = [...tripleIndex];
								tripleIndexCopy[index].studied = !tripleIndex[index].studied;
								setTripleIndex(tripleIndexCopy);
								studyHelpsDispatch({ type: 'SET_TRIPLE_INDEX', payload: { tripleIndex: tripleIndexCopy } });
							}
						}}
					/>
				</TopicAreaContainer>

				<TopicAreaContainer>
					<div style={{ marginLeft: 12, fontWeight: 600 }}>{studyHelpName}</div>
				</TopicAreaContainer>

				<TopicAreaContainer>
					<StudyTopicTitle
						style={{ marginLeft: 10 }}
						onClick={e => {
							handleGetReleventInfo(id, index, combinedIndex, overallIndex, title, studyHelpName, seeAlso);
							// handleOpenStudyTopic({
							// 	originPaneKey: props.uniqueKey,
							// 	open: true,
							// 	selectedOption,
							// 	selectedTopic: { index, combinedIndex, overallIndex, title, seeAlso, studyHelp: studyHelpName },
							// });
							//
						}}
					>
						{title}
					</StudyTopicTitle>
				</TopicAreaContainer>
			</StudyTopic>
		);
	};

	return (
		<StudyHelpsContainer>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
				</Tooltip>
			</ClosePaneWrapper>

			<SearchInputContainer>
				<LibraryText {...props.dragHandleProps}>
					<span onClick={() => props.backToMain()}>Library</span>
					{' > '}
					<span onClick={() => setSelectedTopic(null)}>Study Helps</span>
					{selectedTopic ? ` > ${selectedTopic.title}` : null}
				</LibraryText>
				{!selectedTopic ? (
					<SearchBarContainer>
						<form onSubmit={handleSubmit}>
							<SearchInput
								type='text'
								className='input'
								value={selectedLetter}
								onChange={e => setSelectedLetter(e.target.value)}
								autoComplete='off'
								autoFocus={false}
								name='text'
							/>

							<SearchIconWrapper type='submit'>
								<FontAwesomeIcon icon={faSearch} />
							</SearchIconWrapper>
						</form>
					</SearchBarContainer>
				) : (
					<SingleTopicNav>
						<div style={{ cursor: 'pointer' }}>
							{previousIndex > 0 && (
								<span
									onClick={() => {
										// let currentStudyHelps =
										// 	selectedOption === 'topicalGuide'
										// 		? topicalGuide
										// 		: selectedOption === 'bibleDictionary'
										// 			? bibleDictionary
										// 			: selectedOption === 'tripleIndex'
										// 				? tripleIndex
										// 				: [...topicalGuide, ...bibleDictionary, ...tripleIndex].sort((a, b) =>
										// 					a.title < b.title ? -1 : a.title > b.title ? 1 : 0
										// 				);

										// changeTopic(-1, currentStudyHelps);
										handlePrevNext('prev');
									}}
								>
									Previous{' '}
								</span>
							)}
							/
							{previousIndex + 1 < previousTopics.length && (
								<span
									onClick={() => {
										// let currentStudyHelps =
										// 	selectedOption === 'topicalGuide'
										// 		? topicalGuide
										// 		: selectedOption === 'bibleDictionary'
										// 			? bibleDictionary
										// 			: selectedOption === 'tripleIndex'
										// 				? tripleIndex
										// 				: [...topicalGuide, ...bibleDictionary, ...tripleIndex].sort((a, b) =>
										// 					a.title < b.title ? -1 : a.title > b.title ? 1 : 0
										// 				);

										// changeTopic(1, currentStudyHelps);
										handlePrevNext('next');
									}}
								>
									{' '}
									Next
								</span>
							)}
						</div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginRight: 4 }}>Studied {selectedTopic.studyHelp}</div>
							<FontAwesomeIcon
								size='lg'
								style={{ marginLeft: '8px', cursor: 'pointer' }}
								icon={
									['Topical Guide', 'TG'].includes(selectedTopic.studyHelp)
										? topicalGuide[selectedTopic.index] && topicalGuide[selectedTopic.index].studied
											? faCheckSquare
											: faSquare
										: ['Bible Dictionary', 'BD'].includes(selectedTopic.studyHelp)
										? bibleDictionary[selectedTopic.index] && bibleDictionary[selectedTopic.index].studied
											? faCheckSquare
											: faSquare
										: ['Triple Index', 'TC'].includes(selectedTopic.studyHelp)
										? tripleIndex[selectedTopic.index] && tripleIndex[selectedTopic.index].studied
											? faCheckSquare
											: faSquare
										: faSquare
								}
								onClick={() => {
									// This on click will check to see which study help was clicked, then it will send
									// a POST request to the api to toggle its studied status. Then it creates a copy of the given
									// study help and modifies that copy by updating the current topic's studied status. Then
									// it sets that modified copy to the state variable. Note that I did it through copying because
									// state variables are immutable and in order to modify them a copy should be created and modified.
									if (['Topical Guide', 'TG'].includes(selectedTopic.studyHelp)) {
										toggleStudiedStatus(topicalGuide[selectedTopic.index].id, !topicalGuide[selectedTopic.index].studied);

										let topicalGuideCopy = [...topicalGuide];
										topicalGuideCopy[selectedTopic.index].studied = !topicalGuide[selectedTopic.index].studied;
										setTopicalGuide(topicalGuideCopy);
										studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide: topicalGuideCopy } });
									} else if (['Bible Dictionary', 'BD'].includes(selectedTopic.studyHelp)) {
										toggleStudiedStatus(bibleDictionary[selectedTopic.index].id, !bibleDictionary[selectedTopic.index].studied, 'BD');

										let bibleDictionaryCopy = [...bibleDictionary];
										bibleDictionaryCopy[selectedTopic.index].studied = !bibleDictionary[selectedTopic.index].studied;
										setBibleDictionary(bibleDictionaryCopy);
										studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary: bibleDictionaryCopy } });
									} else if (['Index to TC', 'TC'].includes(selectedTopic.studyHelp)) {
										toggleStudiedStatus(tripleIndex[selectedTopic.index].id, !tripleIndex[selectedTopic.index].studied, 'TC');

										let tripleIndexCopy = [...tripleIndex];
										tripleIndexCopy[selectedTopic.index].studied = !tripleIndex[selectedTopic.index].studied;
										setTripleIndex(tripleIndexCopy);
										studyHelpsDispatch({ type: 'SET_TRIPLE_INDEX', payload: { tripleIndex: tripleIndexCopy } });
									} else {
										// NOTE: We probably don't need this else statement for now, but i'll leave it just in case
									}
								}}
							/>
						</div>
					</SingleTopicNav>
				)}
			</SearchInputContainer>

			{!selectedTopic ? (
				<div style={{ height: 'calc(100vh - 150px)', overflow: 'scroll' }}>
					<FilterSearchContainer>
						{studyOptions.map(({ name, value }, index) => (
							<RadioButtonContainer key={index}>
								<input
									style={{ marginTop: 5, marginLeft: 5 }}
									id={`${props.uniqueKey} studyRadio-${index + 1}`}
									value={value}
									type='radio'
									checked={selectedOption === value}
									onChange={() => {
										if (name === 'Topical Guide' || name === 'All' || name === 'Bible Dictionary' || name === 'Index to TC') {
											// if (name === 'Topical Guide' || name === 'All' || name === 'Bible Dictionary') {
											setSelectedOption(value);
										} else alert('This feature is coming soon!');
									}}
								/>
								<RadioLabel htmlFor={`${props.uniqueKey} studyRadio-${index + 1}`}>{name}</RadioLabel>
							</RadioButtonContainer>
						))}
					</FilterSearchContainer>

					<SectionsContainer>
						<SectionsHeader>Sections</SectionsHeader>
						<SectionsList>
							{alphaList.map((letter, index) => (
								<SectionLetter
									key={index}
									onClick={() => setSelectedLetter(letter)}
									style={
										selectedLetter.toLowerCase().startsWith(letter.toLowerCase()) ? { backgroundColor: '#539ef4', color: '#ffffff' } : {}
									}
								>
									{letter.toUpperCase()}
								</SectionLetter>
							))}
						</SectionsList>
					</SectionsContainer>

					<StudyResults>
						<StudiedBanner>
							<BannerSection style={{ marginLeft: 24, width: 50 }}>Studied</BannerSection>
							<BannerSection>Reference</BannerSection>
							<BannerSection>Title</BannerSection>
						</StudiedBanner>

						<StudyList>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									minHeight: 'min-content',
									height: '100%',
									overflowY: 'auto',
									marginTop: 12,
									marginBottom: 90,
								}}
							>
								{
									// Welcome to the ternary from hell.
									//
									// It looks confusing but it's pretty much just the same couple functions chained
									// together. Basically it renders the an array of the component StudyTopicSection. In order to
									// render the correct components I filter the given study help array by the selected letter(s).
									//
									// I also map before the filter to add an additional index attribute. This way you'll have access
									// to the filtered index as well as the items index in respect to the non-filtered study help array.
									//
									// Currently I only use the filtered index when rendering the combination of all the study help arrays.
									loading ? (
										<div style={{ marginTop: 30 }}>
											<Loading />
										</div>
									) : selectedOption === 'topicalGuide' ? (
										topicalGuide
											.map((tgSec, ind) => ({ ...tgSec, index: ind }))
											.filter(({ title }) =>
												selectedLetter.length === 0
													? title.toLowerCase().startsWith('a')
													: selectedLetter.length <= 2
													? title.toLowerCase().startsWith(selectedLetter.toLowerCase())
													: title.toLowerCase().includes(selectedLetter.toLowerCase())
											)
											.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
											.map(({ title, seeAlso, references, id, studied, index }, indexx) => (
												<StudyTopicSection
													key={index}
													{...{ title, seeAlso, references, index, id, hasStudied: studied, studyHelpName: 'TG' }}
												/>
												// )) : [...bibleDictionary.map(x => ({...x, studyHelpName: 'BD'}))]
											))
									) : selectedOption === 'bibleDictionary' ? (
										// window.alert('Bible Dictionary Coming Soon')
										// bibleDictionary.map((bdSec, ind) => ({ ...bdSec, index: ind })).filter(({ title }) => title.toLowerCase().startsWith(selectedLetter.toLowerCase())).map(({ title, references, id, studied, index }, indexx) => (
										//   references[0] != undefined ?
										//     <StudyTopicSection key={index}  {...{ title, references: references[0].text, index, id, hasStudied: studied, studyHelpName: 'BD' }} /> : null
										// )) : [...bibleDictionary.map(x => ({...x, studyHelpName: 'BD'}))]
										// ))

										//The code below is what was working for BD
										bibleDictionary
											.map((bdSec, ind) => ({ ...bdSec, index: ind }))
											.filter(({ title }) =>
												selectedLetter.length === 0
													? title.toLowerCase().startsWith('a')
													: selectedLetter.length <= 2
													? title.toLowerCase().startsWith(selectedLetter.toLowerCase())
													: title.toLowerCase().includes(selectedLetter.toLowerCase())
											)
											.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
											.map(({ title, seeAlso, references, id, studied, index }, indexx) => (
												<StudyTopicSection
													key={index}
													{...{ title, seeAlso, references, index, id, hasStudied: studied, studyHelpName: 'BD' }}
												/>
												// )) : [...bibleDictionary.map(x => ({...x, studyHelpName: 'BD'}))]
											))
									) : selectedOption === 'tripleIndex' ? (
										tripleIndex
											.map((tcSec, ind) => ({ ...tcSec, index: ind }))
											.filter(({ title }) =>
												selectedLetter.length === 0
													? title.toLowerCase().startsWith('a')
													: selectedLetter.length <= 2
													? title.toLowerCase().startsWith(selectedLetter.toLowerCase())
													: title.toLowerCase().includes(selectedLetter.toLowerCase())
											)
											.map(({ title, seeAlso, references, id, studied, index }, indexx) => (
												<StudyTopicSection
													key={index}
													{...{ title, seeAlso, references, index, id, hasStudied: studied, studyHelpName: 'TC' }}
												/>
											))
									) : //?  Redundant ternery potentially
									selectedOption === 'tripleIndex' ? (
										tripleIndex
											.map((tcSec, ind) => ({ ...tcSec, index: ind }))
											.filter(({ title }) =>
												selectedLetter.length === 0
													? title.toLowerCase().startsWith('a')
													: selectedLetter.length <= 2
													? title.toLowerCase().startsWith(selectedLetter.toLowerCase())
													: title.toLowerCase().includes(selectedLetter.toLowerCase())
											)
											.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
											.map(({ title, seeAlso, references, id, studied, index }, indexx) => (
												<StudyTopicSection
													key={index}
													{...{ title, seeAlso, references, index, id, hasStudied: studied, studyHelpName: 'TC' }}
												/>
											))
									) : (
										[
											...topicalGuide.map((x, idx) => ({ ...x, studyHelpName: 'TG', index: idx })),
											...bibleDictionary.map((x, idx) => ({ ...x, studyHelpName: 'BD', index: idx })),
											...tripleIndex.map((x, idx) => ({ ...x, studyHelpName: 'TC', index: idx })),
										]
											.map((sh, i) => ({ ...sh, overallIndex: i }))
											// .map((tgSec, ind) => ({ ...tgSec, index: ind }))
											.filter(({ title }) =>
												selectedLetter.length === 0
													? title.toLowerCase().startsWith('a')
													: selectedLetter.length <= 2
													? title.toLowerCase().startsWith(selectedLetter.toLowerCase())
													: title.toLowerCase().includes(selectedLetter.toLowerCase())
											)
											.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
											.map(({ title, seeAlso, references, id, studied, index, overallIndex, studyHelpName }, indexx) => (
												<StudyTopicSection
													key={indexx}
													{...{
														title,
														seeAlso,
														references,
														index,
														id,
														hasStudied: studied,
														studyHelpName,
													}}
												/>
											))
									)
								}
							</div>
						</StudyList>
					</StudyResults>
				</div>
			) : (
				<SingleTopicView /* This component is rendered if there's a selectedTopic */ />
			)}
		</StudyHelpsContainer>
	);
};

export default StudyHelps;
