import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import TextareaAutosize from 'react-autosize-textarea';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLink,
	faTimes,
	faEdit,
	faEye,
	faArrowsAlt,
	faObjectUngroup,
	faSearch,
	faBookmark,
	faCheckCircle,
} from '@fortawesome/fontawesome-free-solid/';
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV';
import * as quill from '../Helpers/quill';
import '../../assets/css/quill.bubble.css';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavContext } from '../../ContextProviders/NavProvider';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
import { VersesContext } from '../../ContextProviders/VersesProvider';
import { StudyHelpsContext } from '../../ContextProviders/StudyHelpsProvider';
import {
	removeMarkTag,
	bbwBold,
	bbwUnderline,
	bbwItalic,
	bbwStrike,
	bbwProcessToggle,
	goodToProceedToggle,
	bbwIsSelectionValid,
} from '../BBWFormatter/BBWFormatter';
import { PortalWithState } from 'react-portal';

// import { socket } from '../../socket';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { VerseOptionsContext } from '../../ContextProviders/VerseOptionsProvider';
import { CollectionsPopUpWrapper } from '../Shared/SharedStyles';

let converter = require('hex2dec');

const bookAbbreviations = {
	Judges: 'jdg',
	Ruth: 'rth',
	'Song of Solomon': 'sng',
	John: 'jhn',
	Philippians: 'phl',
	Philemon: 'phm',
	James: 'jas',
	Jude: 'jde',
};

const DraggableWrapper = styled.div`
	width: 100%;
	background: #fafafa;
	margin-bottom: 5px;
	padding: 5px;
	display: flex;
	z-index: 1000;
`;

const FullTextWrapper = styled.div`
	position: relative;
	width: 100%;
	// min-height: 1px;
	line-height: 20px;
	padding-right: 15px;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	display: inline-flex;
	padding-left: 0px;
	z-index: 1;
`;

const HoverEditClickWrapper = styled.div`
	margin-bottom: 0;
	color: #393f47;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	// line-height: 20px;
	white-space: pre-wrap;
	// &:hover {
	// 	background-color: #e1f0fd;
	// }
`;

const VerseNoteTextboxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 0px;
`;

const VerseOverlay = styled.div`
	width: 100%;
	color: rgb(14, 173, 58);
	position: absolute;
	z-index: 5000;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	// align-items: flex-end;
	height: 100%;
`;

const ReadActionWrapper = styled.div`
	display: flex;
`;

const ReadActionGroup = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	margin-right: 0px;
`;

const btnColor = '#b2b7be';
const hoverColor = '#1a56eb';
const greenEyeColor = '#0ead3a';

const ReadActionItem = styled.div`
	color: ${btnColor};
	font-size: 16px;
	border: none;
	position: relative;
	margin-bottom: 3px;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		color: ${hoverColor};
	}
`;

const ReadActionItemInput = styled.input`
	cursor: pointer;
`;

const GreenEyeItem = styled.div`
	&:hover {
		color: ${hoverColor};
	}
`;

const ReadActionsDropdownWrapper = styled.ul`
	position: absolute;
	right: 0;
	top: calc(100% - 0px);
	z-index: 2000;
	width: 335px;
	padding: 10px;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	max-height: 200px;
	overflow: scroll;
	min-height: 55px;
`;

const CloseNotesModalWrapper = styled.div`
	color: #b2b7be;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 14px;
	&:hover {
		color: #47494c;
	}
`;

const ReadActionDropdownItem = styled.li`
	margin-bottom: 5px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 14px;
	line-height: 18px;
	list-style: none;
`;

const ReadActionLink = styled.a`
	color: #000 !important;
`;

const ReadActionDropdownList = styled.ul`
	list-style: none;
	padding-left: 5px;
	margin-bottom: 0;
`;

const ReadActionListItem = styled.li`
	font-size: 12px;
	color: #6a7583;
	cursor: pointer;
`;
const StudyHelpEntry = styled.span`
	font-size: 12px;
	color: #6a7583;
	cursor: pointer;
	&:hover {
		color: blue;
	}
`;
const CheckboxLabel = styled.label`
	font-size: 12px;
	color: #6a7583;
	cursor: pointer;
`;

const HyperlinkItem = styled.div`
	color: #000 !important;
	display: flex;
	align-items: center;
	margin: 4px 0 4px 8px;
	&:hover {
		text-decoration: underline;
	}
`;

const HyperlinkName = styled.a`
	color: #000 !important;
`;

const HyperlinkInfo = styled.a`
	color: blue !important;
	margin-right: 12px;
	font-weight: 700;
`;

const SelectionCount = styled.div`
	position: absolute;
	top: -15px;
	right: -15px;
	text-align: center;
	font-size: 0.8rem;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: blue;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ModalHeadingText = styled.h2`
	color: #393f47;
	font-size: 21px;
	line-height: 27px;
	text-align: left;
	margin-right: 10px;
`;

const TextFormatter = styled.div`
	position: absolute;
	background-color: #444444;
	border-radius: 13px;
	padding: 2px 15px;
	z-index: 999;
`;
const ICIV = styled.div`
	margin-left: 0px;
`;
const OptionMenu = styled.div`
	position: absolute;
	right: 7px;
	top: 2px;
	cursor: pointer;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;
const MappedCollectionsContainer = styled.div``;
const ModalTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
const SearchCNContainer = styled.div``;
const SearchCNInput = styled.input``;
const SearchIconWrapper = styled.div`
	color: #7d8085;
	position: absolute;
	right: 20px;
	top: 53px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const CollectionTitle = styled.div`
	color: #7d8085;
	margin-bottom: 10px;
	&:hover {
		cursor: pointer;
	}
`;
const NumberLink = styled.a`
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;
const PublicCollectionBoxesContainer = styled.div`
	// width: 100%;
	display: flex;
	// align-items: center;
	// justify-content: flex-end;
`;

String.prototype.replaceAt = function (charStartIndex, charEndIndex, strLength, replacement) {
	const begStr = this.slice(0, charStartIndex),
		endStr = this.slice(charEndIndex, strLength);

	return `${begStr}${replacement}${endStr}`;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	background: isDragging && '#d3fad0',
	display: 'flex',
	zIndex: 2000,
	...draggableStyle,
});
const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
`;
const VerseReferenceTitle = styled.p`
	display: flex;
	justify-content: space-between;
	color: #324599;
	text-decoration: underline;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 700;
	// font-size: 19px;
	line-height: 21px;
	text-decoration-color: rgba(50, 69, 153, 0.3);
	&:hover {
		text-decoration: none;
	}
`;
const LdsReferenceTitle = styled.p`
	color: black;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 700;
`;
const BookmarkContainer = styled.div`
	color: #b2b7be;
	position: absolute;
	top: 10px;
	right: 35px;
	cursor: pointer;
	font-size: 14px;
	&:hover {
		color: #47494c;
	}
`;

const Verse = props => {
	const [showFootnotes, setShowFootnotes] = useState(false);
	const [noteText, setNoteText] = useState('');
	const [lastNoteText, setLastNoteText] = useState('');
	const [userMarkUp, setUserMarkUp] = useState('');
	const [userFootnotes, setUserFootnotes] = useState([]);
	const [verse, setVerse] = useState(props.verse);
	const [readOnly, setReadOnly] = useState(false);
	const [greenEye, setGreenEye] = useState(btnColor);
	const [ldsFootnotes, setLdsFootnotes] = useState([]);
	const [tgEntries, setTGEntries] = useState([]);
	const [bdEntries, setBDEntries] = useState([]);
	const [tcEntries, setTCEntries] = useState([]);
	const [verseEdit, setVerseEdit] = useState(true);
	const [showHyperlinks, setShowHyperlinks] = useState(false);
	const [verseNoteUpdating, setVerseNoteUpdating] = useState(false);
	const [selected, setSelected] = useState(false);
	const [jst, setJst] = useState({});
	const [collections, setCollections] = useState([]);
	const [publicCollections, setPublicCollections] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [verseCollections, setVerseCollections] = useState([]);
	const [hideSeen, setHideSeen] = useState(false);
	const [hideMine, setHideMine] = useState(false);
	const [referenceView, setReferenceView] = useState(false);
	const [verseReferences, setVerseReferences] = useState([]);
	const [selectedReference, setSelectedReference] = useState('');
	const [showVerseOverlay, setShowVerseOverlay] = useState(false);
	const { handleAddNewCollectionNote, handleAddCollection, handleOpenVerses, dispatch, handleOpenStudyTopic } = useContext(NavContext);
	const { studyHelpsDispatch } = useContext(StudyHelpsContext);
	const { updateVerseNote, saveUserFootnote } = useContext(CollectionsContext);

	const { getAllFootnotes, versesState, versesDispatch } = useContext(VersesContext);

	const { isProUser, authState } = useContext(AuthContext);

	const {
		selectedTextState,
		setSelectedTextState,
		selectedVerseId,
		setSelectedVerseId,
		optionVerseId,
		setOptionVerseId,
		setOptionVerseProps,
		isOpenOptions,
		setIsOpenOptions,
		setOptionXPosition,
		optionYPosition,
		setOptionYPosition,
		setOptionOpacity,
		setOptionPaneUniqueId,
		selectedFullLi,
		setSelectedFullLi,
	} = useContext(VerseOptionsContext);

	const bookmarkAdded = () => {
		setShowVerseOverlay(true);
		setTimeout(() => setShowVerseOverlay(false), 1000);
	};

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}
	const prevGreenEye = usePrevious(greenEye);
	useEffect(() => {
		if (props.verse && props.verse.verseNote) {
			setNoteText(props.verse.verseNote.verse_note);
		}
		if (props.verse.collectionIds[0] !== null || props.verse.hasUserFootnote === true) {
			setGreenEye(greenEyeColor);
		}
	}, []);

	useEffect(() => {
		const { selectAllVerses } = props;
		if (selectAllVerses === true) {
			setSelected(true);
		} else setSelected(false);
	}, [props.selectAllVerses]);

	useEffect(() => {
		handleBulkSelectedVerses();
	}, [selected]);

	useEffect(() => {
		const { bulkMoveState } = props;
		if (!bulkMoveState.some(e => e.draggableId === props.draggableId)) {
			setSelected(false);
		}
	}, [props.bulkMoveState]);

	useEffect(() => {
		if (authState && authState.user && authState.user.id) {
			// const socket = io(process.env.REACT_APP_BASE_URL, { 'transports': ['websocket'] });
			const socket = props.socket;

			socket.onopen(() => console.log('authState.user socket on like donkey kong'));

			socket.on('get_updated_verse_notes_' + authState.user.id, ({ verseId, newNoteText }) => {
				// console.log('get_updated_verse_notes_ socket on');
				if (verseId === props.verse.id && newNoteText !== noteText && newNoteText !== lastNoteText) {
					setNoteText(newNoteText);
				}
			});

			socket.on('get_updated_verse_markup_' + authState.user.id, ({ verseId, markupText, paneId }) => {
				// console.log('get_updated_verse_markup called here');
				if (parseInt(verseId) === props.verse.id && props.uniqueKey !== paneId) {
					setVerseEdit(true);
					setUserMarkUp(markupText);
				}
			});
			socket.on(`get_updated_verse_greeneye_${authState.user.id}`, ({ greenEyeColor, verseId }) => {
				if (verseId === props.verse.id) {
					setGreenEye(greenEyeColor);
				}
			});
			socket.on(`update_bookmark_list_${authState.user.id}`, ({ verseId, bookmarkId }) => {
				if (props.verse.id === verseId) {
					versesDispatch({ type: 'UPDATE_VERSE_BOOKMARK', payload: { uniqueKey: props.uniqueKey, index: props.index, bookmarkId: bookmarkId } });
				}
			});
			return () => {
				socket.off('get_updated_verse_markup_' + authState.user.id);
				socket.off('get_updated_verse_notes_' + authState.user.id);
				socket.off('get_updated_verse_greeneye_' + authState.user.id);
				socket.off(`update_bookmark_list_${authState.user.id}`);
			};
		}
	}, [authState]);
	useEffect(() => {
		if ((props.userMarkup && !props.toggleLdsFootnotes) || (props.userMarkup && props.toggleLdsFootnotes)) {
			setUserMarkUp(props.userMarkup.verse_text_with_markup);
			setVerseEdit(true);
		}
	}, [props.userMarkup]);
	useEffect(() => {
		// if (userMarkUp && userMarkUp.includes('href')) {
		if ((userMarkUp && userMarkUp.includes('href')) || props.verse.collectionIds[0] !== null || props.verse.hasUserFootnote === true) {
			setGreenEye(greenEyeColor);
			props.socket.emit('update_verse_greeneye', { userId: authState.user.id, verseId: props.verse.id, greenEyeColor: greenEyeColor });
		} else {
			setGreenEye(btnColor);
		}
	}, [userMarkUp]);
	useEffect(() => {
		if (
			versesState &&
			versesState[props.uniqueKey] &&
			versesState[props.uniqueKey].verses &&
			versesState[props.uniqueKey].verses !== 'No chapter found with that book and order'
		) {
			let verseIndex = versesState[props.uniqueKey].verses.findIndex(verse => props.verse.id === verse.id);

			if (verseIndex !== -1) {
				if (versesState[props.uniqueKey].verses[verseIndex].collectionIds[0] !== null || props.verse.hasUserFootnote) {
					let newVerseData = { ...versesState[props.uniqueKey].verses[verseIndex] };
					let verseCopy = { ...props.verse };
					verseCopy.collectionNotes = newVerseData.collectionNotes;
					setVerse(verseCopy);
					setGreenEye(greenEyeColor);
					props.socket.emit('update_verse_greeneye', { userId: authState.user.id, verseId: props.verse.id, greenEyeColor: greenEyeColor });
				} else {
					setGreenEye(btnColor);
				}

				let allFootnotes = versesState[props.uniqueKey].verses[verseIndex].allFootnotes;
				if (allFootnotes && allFootnotes.length > 0) {
					setUserFootnotes(versesState[props.uniqueKey].verses[verseIndex].allFootnotes[0]);
					setLdsFootnotes(removeDuplicates(versesState[props.uniqueKey].verses[verseIndex].allFootnotes[1], 'originVerseId'));
				}
			}
		}
	}, [versesState]);
	// const AUTOSAVE_INTERVAL = 1500;
	// useEffect(() => {
	// 	// const socket = io(process.env.REACT_APP_BASE_URL, {
	// 	// 	transports: ['websocket']
	// 	// });
	// 	const socket = props.socket;

	// 	const timer = setTimeout(() => {
	// 		if (lastNoteText != noteText) {
	// 			updateVerseNote({ verseId: props.verse.id, userId: props.user.id, noteText: noteText });
	// 			setLastNoteText(noteText);
	// 			socket.emit('update_verse_note', { newNoteText: noteText, verseId: props.verse.id, userId: authState.user.id });
	// 		}
	// 	}, AUTOSAVE_INTERVAL);

	// 	return () => clearTimeout(timer);
	// }, [noteText]);

	const handleReadStatus = () => {
		if (readOnly) {
			setReadOnly(false);
		}
	};
	const handleShowHyperLinks = () => {
		if (showHyperlinks) {
			setShowHyperlinks(false);
		} else {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/verses/getJospehSmithTranslation/${props.verse.chapter.book.title}/${props.verse.chapter.order}/${props.verse.number}`
			)
				.then(res => res.json())
				.then(result => {
					setJst(result);
					setShowHyperlinks(true);
				});
		}
	};
	const handleChangeFootnote = key => (text, delta) => {
		if (
			(delta.ops.length === 1 &&
				(delta.ops[0].hasOwnProperty('delete') ||
					delta.ops[0].hasOwnProperty('insert') ||
					(delta.ops[0].hasOwnProperty('retain') && delta.ops[0].retain === 1))) ||
			(delta.ops.length >= 2 &&
				(delta.ops[1].hasOwnProperty('delete') ||
					delta.ops[1].hasOwnProperty('insert') ||
					(delta.ops[1].hasOwnProperty('retain') && delta.ops[1].retain === 1)))
		) {
			setReadOnly(true);
		} else {
			setUserMarkUp(text);
			versesDispatch({ type: 'UPDATE_VERSE_MARKUP', payload: { uniqueKey: props.uniqueKey, index: props.index, text } });
			saveUserFootnote({
				userId: props.user.id,
				delta: delta,
				text: text,
				verseId: props.verse.id,
			});

			// const socket = io(process.env.REACT_APP_BASE_URL);
			const socket = props.socket;
			socket.emit('update_verse_markup', { verseId: props.verse.id, markupText: text, userId: authState.user.id });
		}
	};
	const handleVerseRefFetch = async (ldsFootnoteId, selectedRef) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getLdsVerseRefs/${ldsFootnoteId}`)
			.then(response => response.json())
			.then(verses => {
				verses = verses = _.sortBy(
					verses,
					[verse => verse.volumeOrder, verse => verse.bookOrder, verse => verse.chapterOrder, verse => verse.number],
					['asc', 'asc', 'asc', 'asc']
				);
				setVerseReferences(verses);
				setReferenceView(true);
				setSelectedReference(selectedRef);
				// console.log(notes);
			})
			.catch(err => console.log('Error getting search results:', err));
	};
	const handleNotesFetch = async () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getAllNotes/${props.user.id}/${props.verse.id}`)
			.then(response => response.json())
			.then(notes => {
				setVerseCollections(notes);
				setShowFootnotes(true);
			})
			.catch(err => console.log('Error getting search results:', err));

		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getPublicNotesByVerseId/${props.verse.id}`)
			.then(response => response.json())
			.then(notes => {
				setPublicCollections(notes);
				// console.log(notes);
			})
			.catch(err => console.log('Error getting search results:', err));

		const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getSeenPublicCollections/${authState.user.id}`)
			.then(res => res.json())
			.then(publicCollections => {
				return publicCollections;
			});
		// if (hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getLdsFootnotesByVerse/${props.verse.id}/${props.verse.book_id}/${props.verse.chapter.id}`)
			.then(response => response.json())
			.then(footnotes => {
				setLdsFootnotes(footnotes.footnotes);
				setTGEntries(footnotes.tgEntries);
				setTCEntries(footnotes.tcEntries);
				setBDEntries(footnotes.bdEntries);
			})
			.catch(err => console.log('Error getting search results:', err));
	};
	function removeDuplicates(array, key) {
		let lookup = new Set();
		return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
	}
	const handleTGEntry = async obj => {
		let topicalGuide = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuide/${props.user.id}/${'a'}`)
			.then(res => res.json())
			.catch(err => console.log('Topical err', err));
		const topicalGuideItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuideTitleInfo/${props.user.id}/${obj.tg_id}`, {
			title: obj.tg_title,
		})
			.then(res => res.json())
			.catch(err => console.log(props.id, 'TG:', err));

		const index = topicalGuide.findIndex(e => e.title === obj.tg_title);

		topicalGuide[index].references = removeDuplicates(topicalGuideItems, 'verseId');

		studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide } });

		handleOpenStudyTopic({
			originPaneKey: props.uniqueKey,
			open: true,
			selectedOption: 'topicalGuide',
			selectedLetter: obj.tg_title.slice(0, 1).toLowerCase(),
			selectedTopic: { combinedIndex: index, overallIndex: index, title: obj.tg_title, seeAlso: obj.tg_subtitle, studyHelp: 'TG', index },
		});
		scrollRight();
	};
	const handleBDEntry = async obj => {
		let bibleDictionary = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionary/${props.user.id}/${'a'}`)
			.then(res => res.json())
			.catch(err => console.log('Topical err', err));
		const bibleDictionaryItems = await fetch(
			`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionaryTitleInfo/${props.user.id}/${obj.bd_text_id}`,
			{
				title: obj.title,
			}
		)
			.then(res => res.json())
			.catch(err => console.log(props.id, 'TG:', err));
		const index = bibleDictionary.findIndex(e => e.title === obj.title);

		bibleDictionary[index].references = removeDuplicates(bibleDictionaryItems.references, 'verseId');
		bibleDictionary[index].text = bibleDictionaryItems.text;

		studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary } });

		handleOpenStudyTopic({
			originPaneKey: props.uniqueKey,
			open: true,
			selectedOption: 'bibleDictionary',
			selectedLetter: obj.title.slice(0, 1).toLowerCase(),
			selectedTopic: { combinedIndex: index, overallIndex: index, title: obj.title, index, studyHelp: 'BD' },
		});
		scrollRight();
	};
	const handleTCEntry = async obj => {
		let tripleIndex = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndex/${props.user.id}/${'a'}`)
			.then(res => res.json())
			.catch(err => console.log('Triple err', err));
		const tripleIndexItems = await fetch(
			`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndexTitleInfo/${props.user.id}/${obj.triple_title_id}`,
			{
				title: obj.triple_title,
			}
		)
			.then(res => res.json())
			.catch(err => console.log(props.id, 'TG:', err));
		console.log(tripleIndexItems);
		const index = tripleIndex.findIndex(e => e.title === obj.triple_title);

		tripleIndex[index].references = removeDuplicates(tripleIndexItems.references, 'verseId');
		tripleIndex[index].text = tripleIndexItems.text;

		studyHelpsDispatch({ type: 'SET_TRIPLE_INDEX', payload: { tripleIndex } });

		handleOpenStudyTopic({
			originPaneKey: props.uniqueKey,
			open: true,
			selectedOption: 'tripleIndex',
			selectedLetter: obj.triple_title.slice(0, 1).toLowerCase(),
			selectedTopic: { combinedIndex: index, overallIndex: index, title: obj.triple_title, index, studyHelp: 'TC' },
		});
		scrollRight();
	};
	const scrollRight = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;

		var newwidth = children.offsetWidth;
		var nwidth = newwidth;
		nwidth = oldwidth;
		var toscrollright = nwidth - rightposition;
		setTimeout(() => {
			if (toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 500);
	};
	const handleBookmark = async () => {
		if (props.verse.bookmarkId) {
			await fetch(`${process.env.REACT_APP_BASE_URL}/verses/deleteBookmark/${props.user.id}/${props.verse.bookmarkId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(bookmarks => {
					versesDispatch({ type: 'UPDATE_VERSE_BOOKMARK', payload: { uniqueKey: props.uniqueKey, index: props.index, bookmarkId: null } });
					props.socket.emit('update_bookmark_list', { userId: authState.user.id, verseId: props.verse.id, bookmarkId: null });
				});
		} else {
			await fetch(`${process.env.REACT_APP_BASE_URL}/verses/bookmarkVerse/${props.user.id}/${props.verse.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(response => response.json())
				.then(response => {
					versesDispatch({
						type: 'UPDATE_VERSE_BOOKMARK',
						payload: { uniqueKey: props.uniqueKey, index: props.index, bookmarkId: response[0].id },
					});
					props.socket.emit('update_bookmark_list', { userId: authState.user.id, verseId: props.verse.id, bookmarkId: response[0].id });
				})
				.catch(err => console.log('Error getting search results:', err));
		}
	};
	let icons = ReactQuill.Quill.import('ui/icons');
	// icons['link'] = '<span class="footnote-icon">FN</span>';
	icons['strike'] = '<span class="footnote-icon double-underline">U</span>';
	icons['h1'] = '<span class="footnote-icon">1828</span>';

	/** Returns the Blue Letter Bible link for the current verse */
	const blueBibleLink = () => {
		let bookName = props.verse.title;
		let firstThree = bookName.toLowerCase().replace(' ', '').substring(0, 3);
		let bookAbbr = bookAbbreviations[bookName] || firstThree;
		return `https://www.blueletterbible.org/kjv/${bookAbbr}/${props.verse.order}/${props.verse.number}/`;
	};
	function removeDuplicates(array, key) {
		let lookup = new Set();
		return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
	}
	let { volume } = props.verse.chapter.book;
	/** Returns the LDS Citation Index link for the current verse */
	const citationIndexLink = () => {
		let { order, volume } = props.verse.chapter.book;
		const citationIndexRef = {
			'Book of Mormon': '0c9',
			'Old Testament': '065',
			'New Testament': '08c',
			'Doctrine and Covenants': `12${props.verse.chapter.order > 138 ? 'f' : 'e'}`,
			'Pearl of Great Price': '191',
		};
		let startingHexAsNumber = converter.hexToDec(citationIndexRef[volume.title]);
		if (volume.title === 'Doctrine and Covenants' && order === 3) order = 1;
		let bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 1).toString(), { prefix: false });
		if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;

		// These are special contingencies to keep accurate order with the LDS Citation Index site, as they
		// have a few differences in their database as compared to ours

		if (volume.title === 'Book of Mormon') {
			if (props.verse.title === 'Title Page') return 'https://scriptures.byu.edu/#0c9::c0c9';
			if (props.verse.title === 'Introduction') return 'https://scriptures.byu.edu/#0ca::c0ca';
			if (props.verse.title === 'Testimony of Three Witnesses') return 'https://scriptures.byu.edu/#0cb::c0cb';
			if (props.verse.title === 'Testimony of Eight Witnesses') return 'https://scriptures.byu.edu/#0cc::c0cc';
			if (props.verse.title === 'Testimony of the Prophet Joseph Smith') return;
			if (props.verse.title === 'Brief Explanation about the Book of Mormon') return;

			bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 3).toString(), { prefix: false });
			if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		} else if (volume.title === 'Pearl of Great Price' && order > 2) {
			bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order).toString(), { prefix: false });
			if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		}
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.book.order === 1) return 'https://scriptures.byu.edu/#12d::c12d';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 139) return 'https://scriptures.byu.edu/#12f01::c12f010';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 140) return 'https://scriptures.byu.edu/#12f02::c12f020';
		let chapterIndex = converter.decToHex(props.verse.chapter.order.toString(), { prefix: false });
		if (chapterIndex.length < 2) chapterIndex = `0${chapterIndex}`;

		let fullHex = `${bookIndex}${chapterIndex}${props.verse.number}`;
		return `https://scriptures.byu.edu/#${fullHex}::c${bookIndex}${chapterIndex}`;
	};
	const mapIndexLink = () => {
		let { order, volume } = props.verse.chapter.book;
		const chapterOrder = props.verse.chapter.order;
		const bookNumber = {
			'Book of Mormon': order - 2,
			'Old Testament': order,
			'New Testament': order + 39,
			'Doctrine and Covenants': 2,
			'Pearl of Great Price': order,
		};
		const volumeNumber = {
			'Book of Mormon': 2,
			'Old Testament': 1,
			'New Testament': 1,
			'Doctrine and Covenants': 3,
			'Pearl of Great Price': 4,
		};

		if (volume.title === 'Book of Mormon') {
			if (props.verse.title === 'Title Page') return 'https://scriptures.byu.edu/mapscrip/#3:201';
			if (props.verse.title === 'Introduction') return 'https://scriptures.byu.edu/mapscrip/#3:202';
			if (props.verse.title === 'Testimony of Three Witnesses') return 'https://scriptures.byu.edu/mapscrip/#3:203';
			if (props.verse.title === 'Testimony of Eight Witnesses') return 'https://scriptures.byu.edu/mapscrip/#3:204';
			if (props.verse.title === 'Testimony of the Prophet Joseph Smith') return;
			if (props.verse.title === 'Brief Explanation about the Book of Mormon') return;

			// bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 3).toString(), { prefix: false });
			// if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		} else if (volume.title === 'Pearl of Great Price' && order > 2) {
			// bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order).toString(), { prefix: false });
			// if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		}
		// if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.book.order === 1) return 'https://scriptures.byu.edu/#12d::c12d';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 139) return 'https://scriptures.byu.edu/mapscrip/#0:303:1';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 140) return 'https://scriptures.byu.edu/mapscrip/#0:303:2';
		// let chapterIndex = converter.decToHex(props.verse.chapter.order.toString(), { prefix: false });
		// if (chapterIndex.length < 2) chapterIndex = `0${chapterIndex}`;

		return `https://scriptures.byu.edu/mapscrip/#0:${volumeNumber[volume.title]}${
			`${bookNumber[volume.title]}`.length < 2 ? `0${bookNumber[volume.title]}` : bookNumber[volume.title]
		}:${chapterOrder}`;
	};

	const handleGreenEye = async () => {
		setShowFootnotes(!showFootnotes);
		if (props.verse.collectionIds[0] !== null || props.verse.hasUserFootnote === true) {
			setGreenEye(greenEyeColor);
		} else setGreenEye(btnColor);
	};

	const verseObjectRef = useRef(null);

	const replaceAll = (str, find, replace) => {
		return str.replace(new RegExp(find, 'g'), replace);
	};

	const replaceTags = (str, arr, replace) => {
		for (var i = 0; i < arr.length; i++) {
			str = str.replace(new RegExp(arr[i], 'g'), replace);
		}
		return str;
	};

	const showToolBar = (verseArr = null, id = null) => {
		if (verseArr) {
			var verseArrObject = verseArr;
			var liId = id;
		} else {
			var liId = props.textFormatter.current.getAttribute('data-id');
			var userId = props.textFormatter.current.getAttribute('data-userid');
			var verseId = props.textFormatter.current.getAttribute('data-verseid');
			var verseArrObject = verseObjectRef.current;
		}

		// return;
		setSelectedVerseId(liId);
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const pelem = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		const elem = document.querySelector("[data-rbd-draggable-id='" + liId + "']").parentElement;
		const x = elem.offsetLeft;
		const px = pelem.offsetLeft;
		const pWidth = pelem.offsetWidth;
		const y = elem.offsetTop;
		const yHeight = elem.offsetHeight;
		const panelsBlockScroll = panelsBlock.scrollLeft + 80;
		// const y = props.verseScrollRef.current.getOffsetTop();

		const yscroll = props.verseScrollRef.current.getScrollTop();
		const yscrollHeight = props.verseScrollRef.current.getScrollHeight();
		const finalYposition = y - yscroll;
		const finalY = finalYposition + 128;
		const finalXposition = px + pWidth;
		setOptionXPosition(finalXposition - panelsBlockScroll);
		setOptionYPosition(finalY + 30);
		setOptionOpacity(1);
		setIsOpenOptions(true);
		setOptionVerseProps(verseArrObject);

		setOptionPaneUniqueId(props.uniqueKey);
		setOptionVerseId(verseId);
		props.textFormatter.current.style.display = 'none';
	};
	const getFormatter = (id, verseArr, ypos) => {
		const selectedText = window.getSelection().toString();
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			props.textFormatter.current.style.display = 'none';
			return;
		}
		try {
			removeMarkTag();
		} catch {}
		if (selectedText != '') {
			// alert(id)
			var textFormatterClasses = document.querySelectorAll('.TextFormatterClass');
			textFormatterClasses.forEach(textFormatterClass => {
				textFormatterClass.style.display = 'none';
			});
			verseObjectRef.current = verseArr;
			const yscroll = props.verseScrollRef.current.getScrollTop();
			props.textFormatter.current.style.display = 'block';
			props.textFormatter.current.setAttribute('data-id', id);
			props.textFormatter.current.setAttribute('data-verseid', verseArr.id);
			props.textFormatter.current.setAttribute('data-verseobject', verseArr);
			props.textFormatter.current.setAttribute('data-userid', authState.user.id);
			// console.log(authState.user.id)
			props.textFormatter.current.style.top = yscroll + ypos - 200 + 'px';

			//--------------------REMOVING data-bbw-content ----------------- START
			const bbwContents = document.querySelectorAll('[data-bbw-content]');
			bbwContents.forEach(me => {
				me.removeAttribute('data-bbw-content');
			});
			document.getElementById(id).setAttribute('data-bbw-content', 'true');
			//--------------------REMOVING data-bbw-content ----------------- END
		} else {
			props.textFormatter.current.style.display = 'none';
		}
	};
	const renderVerse = () => {
		if (!verseEdit && !props.toggleLdsFootnotes) {
			return (
				<HoverEditClickWrapper
				// onMouseEnter={() => setVerseEdit(!verseEdit)}
				// data-toggle='tooltip'
				// title='Click to edit'
				>
					{props.verse.text}
				</HoverEditClickWrapper>
			);
		} else if (verseEdit && !props.toggleLdsFootnotes) {
			let modifiedVerseText = userMarkUp;
			modifiedVerseText = replaceTags(modifiedVerseText, ['<mark>', '</mark>', '<p>', '</p>'], '');
			modifiedVerseText = replaceTags(modifiedVerseText, ['<b>'], '<strong>');
			modifiedVerseText = replaceTags(modifiedVerseText, ['</b>'], '</strong>');
			modifiedVerseText = replaceTags(modifiedVerseText, ['<i>'], '<em>');
			modifiedVerseText = replaceTags(modifiedVerseText, ['</i>'], '</em>');

			return (
				<>
					<div
						className='singleVerseText'
						id={props.uniqueKey + '-' + props.verse.id}
						onPointerUp={e => getFormatter(props.uniqueKey + '-' + props.verse.id, props.verse, e.clientY)}
						dangerouslySetInnerHTML={{ __html: userMarkUp ? modifiedVerseText : props.verse.text }}
					/>
				</>
			);
		} else if (props.toggleLdsFootnotes) {
			return (
				<div
					style={{ fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif', color: '#393f47' }}
					dangerouslySetInnerHTML={{ __html: props.verse.ldsFootnoteMarkup }}
				></div>
			);
		}
	};
	const handleBulkSelectedVerses = () => {
		if (selected) {
			props.addVerse({ draggableId: props.draggableId, index: props.index, droppableId: props.uniqueKey });
		}
		if (!selected) {
			props.removeVerse(props.draggableId);
		}
	};
	const fetchCollections = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getCollectionList/${props.user.id}/null/null/false`)
			.then(res => res.json())
			.then(collections => {
				setCollections(collections);
			});
		return;
	};
	const handleSaveToCN = async collection => {
		if (selected) {
			props.bulkMoveModal({ collectionId: collection.id, uniqueKey: props.uniqueKey, draggableId: props.draggableId });
			props.socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id, uniqueKey: props.uniqueKey, collectionId: collection.id });
		} else {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}/collections/addVerse/${props.verse.number}/${props.verse.chapter_id}/${props.verse.id}/${collection.id}/${props.user.id}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			).then(res => {
				props.socket.emit('update_cn_list', {
					fromVerse: true,
					userId: authState.user.id,
					uniqueKey: props.uniqueKey,
					collectionId: collection.id,
				});
			});
		}
	};
	const handleOpenChronology = async number => {
		const chapterInfo = await fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getChronologyChapter/${number}`)
			.then(response => response.json())
			.then(chapterInfo => chapterInfo);
		handleOpenVerses({ bookId: 84, chapterId: chapterInfo.id, originPaneKey: props.uniqueKey, proUser: isProUser() });
	};
	const handleSubmit = async e => {
		e.preventDefault();
		if (searchText === '') {
			return fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getCollectionList/${props.user.id}/null/null/false`)
				.then(res => res.json())
				.then(collections => setCollections(collections));
		}
		fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/searchCollectionList/${props.user.id}/${searchText}/null/null/false`)
			.then(res => res.json())
			.then(collections => setCollections(collections));
	};
	const toggleSeen = async (seen, collectionId) => {
		if (seen) {
			const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/removeFromSeenList/${authState.user.id}/${collectionId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(publicCollections => {
					return publicCollections;
				});
			// if (hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
			props.setSeenList(seenList);
			return;
		}
		const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/addToSeenList/${authState.user.id}/${collectionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(publicCollections => {
				return publicCollections;
			});
		// if(hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e,i) => !seenList.some((el) => e.id === el.collection_id)))
		props.setSeenList(seenList);
	};
	const publicCollectionsFilter = array => {
		if (props.hideDone && props.hideMine)
			return array.filter(cn => cn.name !== authState.user.name && !props.seenList.some(el => cn.id === el.collection_id));
		if (props.hideDone && !props.hideMine) return array.filter(cn => !props.seenList.some(el => cn.id === el.collection_id));
		if (!props.hideDone && props.hideMine) return array.filter(cn => cn.name !== authState.user.name);
		return array;
	};
	const toggleHideMine = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/toggleHideMine/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bool: !props.hideMine,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.setHideMine(!props.hideMine);
			})
			.catch(err => console.log(err));
	};
	const toggleHideDone = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/toggleHideDone/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bool: !props.hideDone,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.setHideDone(!props.hideDone);
			})
			.catch(err => console.log(err));
	};
	if (!props.verse.text.includes('<tr>')) {
		return (
			<DraggableWrapper
				ref={props.dragProvided.innerRef}
				{...props.dragProvided.draggableProps}
				style={getItemStyle(props.isDragging, props.dragProvided.draggableProps.style)}
			>
				<FullTextWrapper style={{ paddingLeft: props.verse.verseIndent * 20, paddingRight: props.verse.verseIndent * 30 }} className='quill'>
					{renderVerse()}
				</FullTextWrapper>

				{props.show && (
					<VerseNoteTextboxWrapper>
						<TextareaAutosize
							// disabled={!isProUser()}
							style={{ backgroundColor: verseNoteUpdating ? '#efefef10' : 'white' }}
							className='text-area-wrapper'
							// disabled={(verse && verse.note && verse.note.loading) || this.props.user.data.stripePlanName === 'Free' ? true : false}
							maxRows={20}
							name={props.verse.id}
							value={noteText}
							onChange={e => {
								if (!verseNoteUpdating) setVerseNoteUpdating(true);
								setNoteText(e.target.value);
							}}
							onBlur={() => {
								updateVerseNote({ verseId: props.verse.id, userId: props.user.id, noteText: noteText });
								versesDispatch({ type: 'UPDATE_VERSE_NOTE', payload: { uniqueKey: props.uniqueKey, index: props.index, noteText } });
								// const socket = io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] });
								const socket = props.socket;
								socket.emit('update_verse_note', { newNoteText: noteText, verseId: props.verse.id, userId: authState.user.id });
								setVerseNoteUpdating(false);
							}}
							tabIndex='1'
						/>
					</VerseNoteTextboxWrapper>
				)}

				<ReadActionWrapper>
					<ReadActionGroup>
						<ReadActionItem
							onClick={e => {
								if (!isProUser()) {
									alert('You must be a pro member to access this feature.');
									return;
								}
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(
										`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=new&verseId=${props.verse.id}&bookId=${props.verse.book_id}&chapterId=${props.verse.chapter_id}`,
										'_blank'
									);
								} else {
									if (!props.verse.userMarkup) {
										handleAddNewCollectionNote([props.verse], props.uniqueKey);
										props.verse.userMarkup = {};
										props.verse.userMarkup.verse_text_with_markup = userMarkUp;
									} else handleAddNewCollectionNote([props.verse], props.uniqueKey);
								}
							}}
						>
							<Tooltip content='Create Collection Note'>
								<FontAwesomeIcon icon={faEdit} />
							</Tooltip>
						</ReadActionItem>

						<ReadActionItem>
							<GreenEyeItem
								style={{
									color: showFootnotes ? hoverColor : greenEye,
								}}
								onClick={() => {
									showFootnotes ? setShowFootnotes(false) : handleNotesFetch();
									// getAllFootnotes({ userId: props.user.id, verseId: props.verse.id, uniqueKey: props.uniqueKey });
								}}
								onMouseOver={() => {
									setGreenEye(hoverColor);
								}}
								onMouseOut={() => {
									setGreenEye(prevGreenEye);
								}}
							>
								<Tooltip content='See Notes'>
									<FontAwesomeIcon icon={faEye} />
								</Tooltip>
							</GreenEyeItem>
							{showFootnotes &&
								(referenceView ? (
									<ReadActionsDropdownWrapper>
										<CloseNotesModalWrapper onClick={() => setReferenceView(false)}>
											{/* <FontAwesomeIcon icon={faTimes} onClick={() => setReferenceView(false)} /> */}
											Back
										</CloseNotesModalWrapper>
										<ReadActionDropdownItem>
											<LdsReferenceTitle>{selectedReference}</LdsReferenceTitle>
											<ReadActionDropdownList>
												{/* <Scrollbars> */}
												{verseReferences.length > 0 &&
													verseReferences.map((verse, index) => {
														return (
															<ReadActionListItem
																key={`${index}-${verse.verseId}`}
																onClick={e => {
																	e.persist();
																	e.stopPropagation();
																	if (e.ctrlKey || e.metaKey) {
																		return window.open(
																			`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${verse.bookTitle}&chapter=${verse.chapterId}&book=${verse.bookId}&verseNumber=${verse.number}`,
																			'_blank'
																		);
																	} else {
																		handleOpenVerses({
																			chapterId: verse.chapterId,
																			initialChapterId: verse.chapterId,
																			bookId: verse.bookId,
																			originPaneKey: props.uniqueKey,
																			verseNumber: verse.number,
																			proUser: isProUser(),
																		});
																	}
																}}
															>
																<VerseReferenceTitle>
																	{verse.bookAbbreviation}.{verse.chapterOrder}:{verse.number}
																</VerseReferenceTitle>
																{/* <p>{verse.text}</p> */}
																<p dangerouslySetInnerHTML={{ __html: verse.text }}></p>
															</ReadActionListItem>
														);
													})}
												{/* </Scrollbars> */}
												{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>
									</ReadActionsDropdownWrapper>
								) : (
									<ReadActionsDropdownWrapper>
										<CloseNotesModalWrapper>
											<FontAwesomeIcon icon={faTimes} onClick={() => handleGreenEye()} />
										</CloseNotesModalWrapper>
										{/* <ReadActionDropdownItem>
									<ReadActionLink>Footnotes</ReadActionLink>
									<ReadActionDropdownList>
										{userFootnotes.length > 0
											? userFootnotes.map((note, index) => {
												return (
													<ReadActionListItem key={index}>
														<span>{note.footnote}</span>
													</ReadActionListItem>
												);
											})
											: null}
									</ReadActionDropdownList>
								</ReadActionDropdownItem> */}

										{/* <Scrollbars> */}
										{ldsFootnotes.length > 0 && (
											<ReadActionDropdownItem>
												<ReadActionLink>LDS Footnotes</ReadActionLink>
												<ReadActionDropdownList>
													{ldsFootnotes.length > 0 &&
														ldsFootnotes.map((note, index) => {
															return (
																<ReadActionListItem
																	onClick={() => handleVerseRefFetch(note.id, note.footnote)}
																	key={`${index}-${note.id}`}
																>
																	{`${note.superscript}:${note.footnote}`}
																</ReadActionListItem>
															);
														})}
													{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
												</ReadActionDropdownList>
											</ReadActionDropdownItem>
										)}
										{tgEntries.length > 0 && (
											<ReadActionDropdownItem>
												<ReadActionLink>TG Entries</ReadActionLink>
												<ReadActionDropdownList>
													{tgEntries.length > 0 &&
														tgEntries.map((entry, index) => {
															return (
																<StudyHelpEntry onClick={() => handleTGEntry(entry)} key={`${index}-${entry.id}`}>
																	{`TG ${entry.tg_title}${index === tgEntries.length - 1 ? '' : '; '}`}
																</StudyHelpEntry>
															);
														})}
													{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
												</ReadActionDropdownList>
											</ReadActionDropdownItem>
										)}
										{bdEntries.length > 0 && (
											<ReadActionDropdownItem>
												<ReadActionLink>BD Entries</ReadActionLink>
												<ReadActionDropdownList>
													{bdEntries.length > 0 &&
														bdEntries.map((entry, index) => {
															return (
																<StudyHelpEntry onClick={() => handleBDEntry(entry)} key={`${index}-${entry.id}`}>
																	{`BD ${entry.title}${index === bdEntries.length - 1 ? '' : '; '}`}
																</StudyHelpEntry>
															);
														})}
													{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
												</ReadActionDropdownList>
											</ReadActionDropdownItem>
										)}
										{tcEntries.length > 0 && (
											<ReadActionDropdownItem>
												<ReadActionLink>TC Entries</ReadActionLink>
												<ReadActionDropdownList>
													{tcEntries.length > 0 &&
														tcEntries.map((entry, index) => {
															return (
																<StudyHelpEntry onClick={() => handleTCEntry(entry)} key={`${index}-${entry.id}`}>
																	{`TC ${entry.triple_title}${index === tcEntries.length - 1 ? '' : '; '}`}
																</StudyHelpEntry>
															);
														})}
													{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
												</ReadActionDropdownList>
											</ReadActionDropdownItem>
										)}
										<ReadActionDropdownItem>
											<ReadActionLink>Collection Notes</ReadActionLink>
											<ReadActionDropdownList>
												{verseCollections.length !== 0 ? (
													verseCollections.map(value => {
														return (
															<ReadActionListItem key={value.id} onClick={() => handleAddCollection(value.id, props.uniqueKey)}>
																<span>{value.title}</span>
															</ReadActionListItem>
														);
													})
												) : (
													<ReadActionListItem style={{ fontStyle: 'italic' }}>none</ReadActionListItem>
												)}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>
										{publicCollections.length !== 0 && (
											<ReadActionDropdownItem>
												<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
													<ReadActionLink>Public Collections</ReadActionLink>
													<PublicCollectionBoxesContainer>
														<input
															type='checkbox'
															value={'hide-seen'}
															checked={props.hideDone}
															id='hide-seen'
															onChange={() => toggleHideDone()}
															style={{ cursor: 'pointer', marginRight: '5px' }}
														/>
														<CheckboxLabel style={{ cursor: 'pointer', marginRight: '5px' }} htmlFor='hide-seen'>
															Hide Done
														</CheckboxLabel>
														<input
															type='checkbox'
															value={'hide-mine'}
															checked={props.hideMine}
															id='hide-mine'
															onChange={() => toggleHideMine()}
															style={{ cursor: 'pointer', marginRight: '5px' }}
														/>
														<CheckboxLabel style={{ cursor: 'pointer', marginRight: '5px' }} htmlFor='hide-mine'>
															Hide Mine
														</CheckboxLabel>
													</PublicCollectionBoxesContainer>
												</div>
												<ReadActionDropdownList>
													{publicCollections.length !== 0 &&
														publicCollectionsFilter(publicCollections).map(value => {
															return (
																<ReadActionListItem key={value.id}>
																	<span>
																		<input
																			type='checkbox'
																			value={'seen'}
																			checked={props.seenList.map(e => e.collection_id).includes(value.id)}
																			id='seen'
																			onChange={() =>
																				toggleSeen(props.seenList.map(e => e.collection_id).includes(value.id), value.id)
																			}
																			style={{ cursor: 'pointer', marginRight: '5px' }}
																		/>
																	</span>
																	<span
																		onClick={() => {
																			props.scrollRight();
																			dispatch({
																				type: 'HANDLE_ADD_PANE',
																				originPaneKey: props.uniqueKey,
																				payload: {
																					key: `collectionNote_${props.unique()}`,
																					type: 'collectionNote',
																					collectionId: null,
																					shareCode: value.share_code,
																				},
																			});
																		}}
																	>
																		{value.title} <span style={{ color: 'blue' }}>{value.name}</span>
																	</span>
																</ReadActionListItem>
															);
														})}
												</ReadActionDropdownList>
											</ReadActionDropdownItem>
										)}
										{/* </Scrollbars> */}
									</ReadActionsDropdownWrapper>
								))}
						</ReadActionItem>
						<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									<ReadActionItem {...props.dragProvided.dragHandleProps}>
										<Tooltip content={selected ? `Double-click to add to CN or drag Verses` : 'Double-click to add to CN or drag verse'}>
											<FontAwesomeIcon
												key='DragNDropPortal'
												icon={selected ? faObjectUngroup : faArrowsAlt}
												onDoubleClick={async () => {
													await fetchCollections();
													openPortal();
												}}
											/>
										</Tooltip>
									</ReadActionItem>
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<CollectionsPopUpWrapper>
												<ModalTitleContainer>
													<ModalHeadingText>Add Verse to collection</ModalHeadingText>
													<Tooltip content={'Close'}>
														<FontAwesomeIcon
															style={{ color: '#ac323d' }}
															icon={faTimes}
															onClick={() => {
																closePortal();
															}}
														/>
													</Tooltip>
												</ModalTitleContainer>
												<SearchCNContainer>
													<form onSubmit={handleSubmit}>
														<SearchInput
															type='text'
															placeholder='Title Search'
															name='text'
															value={searchText}
															onChange={e => setSearchText(e.target.value)}
															autoComplete='off'
															autoFocus
														/>
														<SearchIconWrapper>
															<FontAwesomeIcon icon={faSearch} onClick={e => handleSubmit(e)} />
														</SearchIconWrapper>
													</form>
												</SearchCNContainer>

												<MappedCollectionsContainer>
													{collections.length > 0 &&
														collections.map((e, i) => {
															return (
																<div key={i}>
																	<CollectionTitle
																		style={{}}
																		onClick={async () => {
																			await handleSaveToCN(e);
																			closePortal();
																		}}
																	>
																		{e.title}
																	</CollectionTitle>
																</div>
															);
														})}
												</MappedCollectionsContainer>
											</CollectionsPopUpWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>

						{props.bulkMove && (
							<ReadActionItem>
								<Tooltip content='Bulk Select'>
									<ReadActionItemInput name='selected' type='checkbox' checked={selected} onChange={e => setSelected(!selected)} />
								</Tooltip>
							</ReadActionItem>
						)}

						<ReadActionItem>
							<Tooltip content='Links'>
								<FontAwesomeIcon icon={faLink} onClick={() => authState.user.id && handleShowHyperLinks()} />
							</Tooltip>
							{showHyperlinks && (
								<ReadActionsDropdownWrapper>
									<CloseNotesModalWrapper>
										<FontAwesomeIcon icon={faTimes} onClick={() => setShowHyperlinks(!showHyperlinks)} />
									</CloseNotesModalWrapper>
									<BookmarkContainer>
										<Tooltip content='Bookmark Verse'>
											<FontAwesomeIcon
												onClick={handleBookmark}
												icon={faBookmark}
												style={props.verse.bookmarkId ? { color: '#324599' } : {}}
											/>
										</Tooltip>
									</BookmarkContainer>
									{props.verse.chapter.book.volume.id <= 5 ? (
										<ReadActionDropdownItem>
											<HyperlinkItem
												onClick={() => {
													props.scrollRight();
													dispatch({
														type: 'HANDLE_ADD_PANE',
														originPaneKey: props.uniqueKey,
														payload: {
															key: `chatgpt_${props.unique()}`,
															type: 'chatgpt',
															verse: {
																volume: props.verse.chapter.book.volume.title,
																book: props.verse.chapter.book.title,
																chapter: props.verse.chapter.order,
																verseNumber: props.verse.number,
															},
														},
													});
												}}
											>
												<HyperlinkInfo>i</HyperlinkInfo>
												<HyperlinkName>
													AI Assistant - Daniel <span style={{ color: '#324599' }}>{`(in Beta)`}</span>
												</HyperlinkName>
											</HyperlinkItem>
											{['Old Testament', 'New Testament'].includes(props.verse.chapter.book.volume.title) && (
												<div>
													<HyperlinkItem>
														<HyperlinkInfo href='https://scripturenotes.com/resource-7-bible-hub' target='_blank'>
															i
														</HyperlinkInfo>
														<HyperlinkName
															href={`https://biblehub.com/${props.verse.title.toLowerCase().replace(' ', '_')}/${props.verse.order}-${
																props.verse.number
															}.htm`}
															target='_blank'
														>
															Bible Hub
														</HyperlinkName>
													</HyperlinkItem>
													<HyperlinkItem>
														<HyperlinkInfo
															href='https://scripturenotes.com/resource-2-strongs-exhaustive-concordance-of-the-bible'
															target='_blank'
														>
															i
														</HyperlinkInfo>
														<HyperlinkName href={blueBibleLink()} target='_blank'>
															Blue Letter Bible
														</HyperlinkName>
													</HyperlinkItem>
												</div>
											)}

											<HyperlinkItem>
												<HyperlinkInfo href='https://scripturenotes.com/resource-3-lds-citation-index' target='_blank'>
													i
												</HyperlinkInfo>
												<HyperlinkName href={citationIndexLink()} target='_blank'>
													LDS Citation Index
												</HyperlinkName>
											</HyperlinkItem>
											{/* <HyperlinkItem>
											<HyperlinkInfo href='https://scripturenotes.com/resource-3-lds-citation-index' target='_blank'>
												i
											</HyperlinkInfo>
											<HyperlinkName href={mapIndexLink()} target='_blank'>
												Maps
											</HyperlinkName>
										</HyperlinkItem> */}

											{Object.keys(jst).length !== 0 && (
												<div>
													<HyperlinkItem>
														<HyperlinkInfo href='https://scripturenotes.com/resource-4-joseph-smith-translation' target='_blank'>
															i
														</HyperlinkInfo>
														<HyperlinkName href={jst.jst_link} target='_blank'>
															Joseph Smith Translation
														</HyperlinkName>
														<ICIV>
															<HyperlinkName style={{ marginLeft: 5, marginRight: 5 }}>|</HyperlinkName>
															<HyperlinkName href={jst.inline_link} target='_blank'>
																Inline
															</HyperlinkName>
														</ICIV>
													</HyperlinkItem>
												</div>
											)}
										</ReadActionDropdownItem>
									) : (
										<ReadActionDropdownItem>
											<HyperlinkItem
												onClick={() => {
													props.scrollRight();
													dispatch({
														type: 'HANDLE_ADD_PANE',
														originPaneKey: props.uniqueKey,
														payload: {
															key: `chatgpt_${props.unique()}`,
															type: 'chatgpt',
															verse: {
																volume: props.verse.chapter.book.volume.title,
																book: props.verse.chapter.book.title,
																chapter: props.verse.chapter.order,
																verseNumber: props.verse.number,
															},
														},
													});
												}}
											>
												<HyperlinkInfo>i</HyperlinkInfo>
												<HyperlinkName>
													AI Assistant - Daniel <span style={{ color: '#324599' }}>{`(in Beta)`}</span>
												</HyperlinkName>
											</HyperlinkItem>
											{/* <ReadActionLink>No links available.</ReadActionLink> */}{' '}
										</ReadActionDropdownItem>
									)}
								</ReadActionsDropdownWrapper>
							)}
						</ReadActionItem>
					</ReadActionGroup>
				</ReadActionWrapper>
				{props.isDragging && <SelectionCount>{props.bulkMoveState.length}</SelectionCount>}
			</DraggableWrapper>
		);
	} else {
		const numbers = props.verse.text.split('<td>').join('').split('</td>');
		return (
			<tr>
				<td dangerouslySetInnerHTML={{ __html: `${numbers[0].slice(5)}` }}></td>
				<td>
					{numbers[1].split(',').map((e, i) => (
						<NumberLink onClick={() => handleOpenChronology(e)} style={{ color: 'blue' }}>{`${e}${
							i < numbers[1].split(',').length - 1 ? ',' : ''
						}`}</NumberLink>
					))}
				</td>
			</tr>
		);
	}
};

export default Verse;
