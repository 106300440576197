import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { faTimes } from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';

import Tutorial from './Tutorial';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
`;

const PaneTitle = styled.h3`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 25px;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;
const TutorialContainer = styled.div`
	height: 87%;
`;
const TrainingPane = props => {
	// Global Storage
	//State
	// const { link, link_text, title, video_id } = props.data;
	const [openVideo, setOpenVideo] = useState(false);
	const [tutorials, setTutorials] = useState([]);
	const { dispatch } = useContext(NavContext);
	useEffect(() => {
		getTutorials();
	}, []);
	const unique = () => {
		return new Date().getTime().toString(36);
	};
	const getTutorials = async () => {
		await axios.get(`${process.env.REACT_APP_BASE_URL}/webHelp/getAllTutorials`).then(res => setTutorials(res.data));
	};
	const handleOpenVideo = () => {
		openVideo === false ? setOpenVideo(true) : setOpenVideo(false);
	};
	const openTrainingPane = async url => {
		dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `videoPlayer_${unique()}`, type: 'videoPlayer', url } });
	};
	//Ref
	const mappedTutorial = tutorials.map((e, i) => {
		return <Tutorial data={e} key={i} openTrainingPane={openTrainingPane} />;
	});
	return (
		<>
			<PaneContainer>
				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='up' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				{/* <Scrollbars autohide> */}
				<TitleDiv {...props.dragHandleProps}>
					<TitleContainer>
						<PaneTitle>Training & Help Resources</PaneTitle>
					</TitleContainer>
				</TitleDiv>
				<TutorialContainer>
					<Scrollbars>{mappedTutorial}</Scrollbars>
				</TutorialContainer>
			</PaneContainer>
		</>
	);
};

export default TrainingPane;
