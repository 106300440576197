import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import '../../assets/css/tags.css';
// import 'jodit/build/jodit.min.css';
import '../../assets/css/collectionNoteEditor.css';
import JoditEditor from 'jodit-pro-react';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import Switch from 'react-switch';
import styled from 'styled-components';
import { CollectionListContext } from '../../ContextProviders/CollectionListProvider';
import { DashboardContext } from '../../ContextProviders/DashboardProvider';
import FreeBanner from '../FreeBanner/FreeBanner';
import ViewModeBanner from '../ViewModeBanner/ViewModeBanner';
import io from 'socket.io-client';
// import SunEditor from 'suneditor-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import SunEditor from 'suneditor-react';
import Tooltip from 'react-tooltip-lite';
import Compressor from 'compressorjs-global';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const LdsFootnoteSwitch = styled.div`
	position: absolute;
	left: 0px;
	top: 10px;
	text-align: center;
	padding: 0px;
	display: flex;
	justify-content: center;
	font-size: 13px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	line-height: 16px;
	z-index: 100;
	width: 215px;
`;

const GrabPaneWrapper = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	height: 110px;
	overflow: visible;
	padding-top: 45px;
	margin-left: 0px;
	padding-right: 0px;
`;

const ToggleSideWrapper = styled.div`
	// width: 100%;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	// margin-top: 15px;
`;

const ToggleOpenPaneWrapper = styled.div`
	font-size: 22px;
	color: #7d8085;
	cursor: pointer;
	padding-right: 10px;
	&:hover {
		color: #1a56eb;
	}
`;

const SaveCnBtn = styled.button`
	margin-top: -6px;
	margin-right: 5px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 12px;
	// line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	padding: 6px 12px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;

const VerseAddedWrapper = styled.div`
	text-align: center;
	font-weight: 800;
	padding-left: 3rem;
	margin-top: 1rem;
`;

const UpdateNTogglePaneWrapper = styled.div`
	display: inline-flex;
	width: 100%;
`;

const ScrollWrapper = styled.div`
	display: inline-flex;
	width: 100%;
	background: #fafafa;
	padding-right: 6px;
	height: 100%;
`;

const TextEditorNUpdateNTogglePaneWrapper = styled.div`
	padding-right: 15px;
	width: 100%;
	// height: calc(100% - 155px);
	// border: thick double #32a1ce;
`;

const ErrorMessage = styled.div`
	color: red;
	font-size: 12px;
	margin: 2px 0;
`;

const FromStudyHelp = styled.div`
	background: #6280ad;
	width: max-content;
	color: #ffffff;
	padding: 4px 8px;
	margin: 4px 0 16px;
	font-size: 12px;
	height: 100%;
	margin-right: 5px;
`;
const ExpiresBanner = styled.div`
	background: rgb(255, 246, 151);
	width: max-content;
	padding: 4px 8px;
	margin: 4px 0 16px;
	font-size: 12px;
	border: 1px solid rgb(255, 162, 162);
	height: 55%;
	margin-right: 5px;
`;
const SharedBanner = styled.div`
	background: #6280ad;
	width: max-content;
	color: #ffffff;
	padding: 4px 8px;
	margin: 4px 0 16px;
	font-size: 12px;
	height: 100%;
	margin-right: 5px;
`;

const DateContainer = styled.div`
	display: flex;
`;

const CustomMarkupButton = styled.button`
	outline: none;
	border: none;
	background: none;
`;
const RemoveSpan = styled.span`
	&:hover {
		cursor: pointer;
	}
`;

const CollectionNoteEditor = props => {
	const editor = useRef(null);
	const editorWrapper = useRef(null);
	const [title, setTitle] = useState('');
	const [text, setText] = useState('');
	const [tags, setTags] = useState([]);
	const [allUsersTags, setAllUsersTags] = useState([]);
	const [categories, setCategories] = useState([]);
	const [stripePlanName, setStripePlanName] = useState('Pro Yearly');
	const [collection, setCollection] = useState({});
	const [isFromVerse, setIsFromVerse] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [tiny, setTiny] = useState(false);
	const [expandEditor, setExpandEditor] = useState(true);
	const [lastNoteText, setLastNoteText] = useState('');

	const {
		collectionState,
		saveCollectionNote,
		updateCollectionNote,
		saveCollectionNoteWithVerses,
		fetchUserCollectionNote,
		updateJournalDate,
		addCNToSharedList,
		handleSaveSharedCN,
	} = useContext(CollectionsContext);
	const [startDate, setStartDate] = useState(
		collectionState[props.uniqueKey].collection && collectionState[props.uniqueKey].collection.id
			? Date.parse(collectionState[props.uniqueKey].collection.created_at)
			: new Date()
	);

	const { updateNoteInList } = useContext(CollectionListContext);
	const { updateNotesViewed } = useContext(DashboardContext);

	const { isProUser, authState } = useContext(AuthContext);

	const { handleUpdateCn } = useContext(NavContext);
	/** Returns a string with the first character capitalized */
	const firstCharUpper = string => string.charAt(0).toUpperCase() + string.slice(1);
	useEffect(() => {
		if (collectionState[props.uniqueKey]) {
			if (collectionState[props.uniqueKey].collection.user_id !== authState.user.id) {
				let currentTags = [...collectionState[props.uniqueKey].collection.tags];
				currentTags.forEach(({ label }, index) => (currentTags[index].label = firstCharUpper(label)));

				if (collectionState[props.uniqueKey].fromVerse) {
					let collectionCopy = { ...collection };
					collectionCopy.verses = collectionState[props.uniqueKey].collection.verses;

					setCollection(collectionCopy);
				} else if (collectionState[props.uniqueKey].collection.id) {
					props.collection.collection.share_settings !== 'scriptures' && setText(collectionState[props.uniqueKey].collection.shared_note);
					setTitle(collectionState[props.uniqueKey].collection.title);
					setCategories(collectionState[props.uniqueKey].collection.categories);
					setAllUsersTags(collectionState[props.uniqueKey].collectionNoteTags);
					setCollection(collectionState[props.uniqueKey].collection);
					setTags(collectionState[props.uniqueKey].collection.tags);

					props.collection.read_only || (!props.collection.can_save && editor.current && editor.current.readOnly(true));
				} else {
					if (collectionState[props.uniqueKey].collection.title) {
						setTitle(collectionState[props.uniqueKey].collection.title);
					}
					if (collectionState[props.uniqueKey].collection.text) {
						setText(collectionState[props.uniqueKey].collection.text);
					}
					if (collectionState[props.uniqueKey].collection.tags.length) {
						setTags(collectionState[props.uniqueKey].collection.tags);
					}
					if (collectionState[props.uniqueKey].collection.categories.length) {
						setCategories(collectionState[props.uniqueKey].collection.categories);
					}

					setAllUsersTags(collectionState[props.uniqueKey].collectionNoteTags);
					setCollection(collectionState[props.uniqueKey].collection);
				}
			} else {
				let currentTags = [...collectionState[props.uniqueKey].collection.tags];
				currentTags.forEach(({ label }, index) => (currentTags[index].label = firstCharUpper(label)));

				if (collectionState[props.uniqueKey].fromVerse) {
					let collectionCopy = { ...collection };
					collectionCopy.verses = collectionState[props.uniqueKey].collection.verses;

					setCollection(collectionCopy);
				} else if (collectionState[props.uniqueKey].collection.id) {
					setText(collectionState[props.uniqueKey].collection.text);
					setTitle(collectionState[props.uniqueKey].collection.title);
					setCategories(collectionState[props.uniqueKey].collection.categories);
					setAllUsersTags(collectionState[props.uniqueKey].collectionNoteTags);
					setCollection(collectionState[props.uniqueKey].collection);
					setTags(collectionState[props.uniqueKey].collection.tags);
				} else {
					if (collectionState[props.uniqueKey].collection.title) {
						setTitle(collectionState[props.uniqueKey].collection.title);
					}
					if (collectionState[props.uniqueKey].collection.text) {
						setText(collectionState[props.uniqueKey].collection.text);
					}
					if (collectionState[props.uniqueKey].collection.tags.length) {
						setTags(collectionState[props.uniqueKey].collection.tags);
					}
					if (collectionState[props.uniqueKey].collection.categories.length) {
						setCategories(collectionState[props.uniqueKey].collection.categories);
					}

					setAllUsersTags(collectionState[props.uniqueKey].collectionNoteTags);
					setCollection(collectionState[props.uniqueKey].collection);
				}
			}
		}
	}, [collectionState[props.uniqueKey]]);
	useEffect(() => {
		if (editor && editor.current && expandEditor) {
			let buttonList = props.show
				? [
						['undo', 'redo'],
						[
							// ':i-More Misc-default.more_vertical',
							'font',
							'fontSize',
							'formatBlock',
						],
						['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
						['removeFormat'],
						['outdent', 'indent'],
						['align', 'list'],
						[
							'paragraphStyle',
							'blockquote',
							'table',
							'horizontalRule',
							'strike',
							'lineHeight',
							'link',
							'image',
							'video',
							'imageGallery',
							'codeView',
							'preview',
						],
				  ]
				: [
						['undo', 'redo'],
						[
							// ':i-More Misc-default.more_vertical',
							'font',
							'fontSize',
							'formatBlock',
						],
						['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
						['removeFormat'],
						['outdent', 'indent'],
						['align', 'list'],
				  ];
			editor.current.setToolbarButtons(buttonList);
		}
	}, [props.show]);
	//TODO	Set and autosave feature
	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		if (lastNoteText !== text) {
	// 			handleUpdateCN()
	// 			setLastNoteText(text);
	// 		}
	// 	}, 5000);

	// 	return () => clearTimeout(timer);

	// }, [text]);
	useEffect(() => {
		const socket = props.socket;

		socket.on('get_updated_cn_list_' + authState.user.id, ({ fromVerse = false, uniqueKey, collectionId }) => {
			if (props.uniqueKey !== uniqueKey && collectionId === props.collectionNoteId) {
				if (props.collectionNoteId) {
					fetchUserCollectionNote({
						uniqueKey: props.uniqueKey,
						userId: props.user.id,
						collectionId: props.collectionNoteId,
						fromVerse,
						noSave: true,
					});
				}
			}
		});

		return () => {
			socket.off('get_updated_cn_list_');
		};
	}, [authState]);
	const freeConfigShow = {
		readonly: true,
		toolbarAdaptive: false,
		// additional options requested by oak
		memorizeChoiceWhenPasteFragment: false,
		disablePlugins: ['cleanHtml'],
		showXPathInStatusbar: false,
		askBeforePasteHTML: true,
		askBeforePasteFromWord: true,
		processPaste: function (event, html) {
			editor.selection.insertHTML(html);
			editor.tempContent = editor.getEditorValue();
		},
		defaultActionOnPaste: 'insert_clean_html',
		// end
		height: 150,
		maxHeight: 800,
		buttons: [
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'ul',
			'ol',
			'outdent',
			'indent',
			'superscript',
			'subscript',
			'align',
			'font',
			'paragraph',
			'fontsize',
			'brush',
			'cut',
			'hr',
			'eraser',
			'source',
			'link',
			'table',
			'image',
			'file',
			'video',
			'symbol',
			'selectall',
			'print',
			'undo',
			'redo',
		],
	};

	const freeConfig = {
		readonly: true,
		toolbarAdaptive: false,
		// additional options requested by oak
		memorizeChoiceWhenPasteFragment: false,
		disablePlugins: ['cleanHtml'],
		showXPathInStatusbar: false,
		askBeforePasteHTML: true,
		askBeforePasteFromWord: true,
		defaultActionOnPaste: 'insert_clean_html',
		// end
		height: 150,
		maxHeight: 800,
		buttons: ['bold', 'italic', 'underline', 'ul', 'ol', 'left', '\n', 'fontsize', 'paragraph', 'brush', 'undo', 'redo'],
	};
	const proConfigShow = {
		readonly: false,
		toolbarAdaptive: false,
		// additional options requested by oak
		memorizeChoiceWhenPasteFragment: false,
		disablePlugins: ['cleanHtml'],
		showXPathInStatusbar: false,
		askBeforePasteHTML: true,
		askBeforePasteFromWord: true,
		defaultActionOnPaste: 'insert_clean_html',
		// end
		height: 300,
		maxHeight: 600,
		buttons: [
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'ul',
			'ol',
			'outdent',
			'indent',
			'superscript',
			'subscript',
			'align',
			'font',
			'paragraph',
			'fontsize',
			'brush',
			'cut',
			'hr',
			'eraser',
			'source',
			'link',
			'table',
			'image',
			'file',
			'video',
			'symbol',
			'selectall',
			'print',
			'undo',
			'redo',
		],
	};
	const proConfig = {
		readonly: false,
		toolbarAdaptive: false,
		// additional options requested by oak
		memorizeChoiceWhenPasteFragment: false,
		disablePlugins: ['cleanHtml'],
		showXPathInStatusbar: false,
		askBeforePasteHTML: true,
		askBeforePasteFromWord: true,
		defaultActionOnPaste: 'insert_clean_html',
		// end
		height: 300,
		maxHeight: 600,
		buttons: ['bold', 'italic', 'underline', 'ul', 'ol', 'left', '\n', 'fontsize', 'paragraph', 'brush', 'undo', 'redo'],
	};

	const handleCreateCN = async e => {
		if (!title) {
			setErrorMessage('A title is required for a collection note.');
			return;
		}
		setErrorMessage(null);
		if (e) {
			e.preventDefault();
		}

		const verseArr =
			collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection && collectionState[props.uniqueKey].collection.verses
				? collectionState[props.uniqueKey].collection.verses
				: props.collectionObj.verses
				? props.collectionObj.verses
				: null;

		// const verseArr = props.collectionObj.verses ? props.collectionObj.verses : null;
		// Creating CN with Verses attached.
		if (verseArr) {
			saveCollectionNoteWithVerses(
				{
					uniqueKey: props.uniqueKey,
					studyHelpInfo: props.studyHelpInfo,
					userId: props.user.id,
					title,
					text,
					categories,
					tags,
					originatedFrom: props.collection.collection.shared_by ? props.collection.collection.shared_by : null,
					verses: verseArr,
				},
				handleUpdateCn,
				props.verseAdded
			);
			props.socket.emit('update_cn_list', {
				fromVerse: false,
				userId: authState.user.id,
				uniqueKey: props.uniqueKey,
				collectionId: props.collectionNoteId,
			});
			// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }).emit('update_cn_list', { fromVerse: false, userId: authState.user.id });
		} else {
			// Creating CN without Verses attached.
			saveCollectionNote(
				{
					uniqueKey: props.uniqueKey,
					studyHelpInfo: props.studyHelpInfo,
					userId: props.user.id,
					title,
					text,
					categories,
					tags,
					originatedFrom: props.collection.collection.shared_by ? props.collection.collection.shared_by : null,
				},
				handleUpdateCn,
				props.verseAdded
			);
			props.socket.emit('update_cn_list', {
				fromVerse: false,
				userId: authState.user.id,
				uniqueKey: props.uniqueKey,
				collectionId: props.collectionNoteId,
			});
			// io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }).emit('update_cn_list', { fromVerse: false, userId: authState.user.id });
		}
	};

	const handleUpdateCN = e => {
		if (e) {
			e.preventDefault();
		}

		updateCollectionNote({
			title,
			text,
			collectionId: props.collectionNoteId,
			categories,
			tags,
			userId: props.user.id,
			uniqueKey: props.uniqueKey,
			verseAdded: props.verseAdded,
		});
		props.socket.emit('update_cn_list', {
			fromVerse: false,
			userId: authState.user.id,
			uniqueKey: props.uniqueKey,
			collectionId: props.collectionNoteId,
		});
	};

	const handleCategoryChange = selectedOption => {
		setCategories(selectedOption);
	};
	const handleRemoveStudyHelp = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/removeStudyHelp/${authState.user.id}/${props.collectionNoteId}`, {
			method: 'DELETE',
		}).then(res => res.json());
		fetchUserCollectionNote({
			uniqueKey: props.uniqueKey,
			userId: props.user.id,
			collectionId: props.collectionNoteId,
			fromVerse: false,
			noSave: true,
		});
	};
	const handleRemoveSharedBy = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/removeSharedBy/${authState.user.id}/${props.collectionNoteId}`, {
			method: 'DELETE',
		}).then(res => res.json());
		fetchUserCollectionNote({
			uniqueKey: props.uniqueKey,
			userId: props.user.id,
			collectionId: props.collectionNoteId,
			fromVerse: false,
			noSave: true,
		});
	};
	const handleTagChange = selectedOption => {
		setTags(selectedOption);
	};
	const handleEditorChange = (content, editor) => {
		// setText(content);

		setText(content);
	};
	const handleUpdateDate = date => {
		const data = {
			date,
			userId: props.user.id,
			collectionId: collectionState[props.uniqueKey].collection.id,
			uniqueKey: props.uniqueKey,
		};
		if (collectionState[props.uniqueKey].collection.id) {
			const data = {
				date,
				userId: props.user.id,
				collectionId: collectionState[props.uniqueKey].collection.id,
				uniqueKey: props.uniqueKey,
			};
			updateJournalDate(data);
			setStartDate(date);
		}
	};
	const isOwner = () => {
		if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection) {
			return authState.user.id === collectionState[props.uniqueKey].collection.user_id;
		}
	};
	const canEdit = select => {
		if (isProUser()) {
			if (props.collectionNoteId === null) return false;
			if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection) {
				if (isOwner()) {
					if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection.read_only) {
						if (select === 'select') return false;
						return true;
					}
					return false;
				}
				if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection) {
					if (collectionState[props.uniqueKey].collection.can_save) {
						return false;
					}
					return true;
				}
				return true;
			}
		} else return true;
	};
	/** Returns the abbreviated version of the given study help name */
	const abbreviatedStudyHelp = studyHelpName => {
		switch (studyHelpName) {
			case 'Topical Guide':
				return 'TG';
			case 'Bible Dictionary':
				return 'BD';

			// NOTE: I'm not sure if we should use ITC or TC for the index to the triple
			// and also i've been using Index to TC instead of the full name, but I'm going to
			// leave this case here in case we want to use it instead.
			case 'Index to The Triple Combination':
				return 'ITC';
			case 'Index to TC':
				return 'TC';
			default:
				return studyHelpName;
		}
	};
	const createdAt =
		collectionState[props.uniqueKey].collection.id && collectionState[props.uniqueKey].collection.created_at.split('T')[0].split('-');
	const updatedAt =
		collectionState[props.uniqueKey].collection.id && collectionState[props.uniqueKey].collection.updated_at.split('T')[0].split('-');
	// const updatedAt = collectionState[props.uniqueKey].collection.id && Date.parse(collectionState[props.uniqueKey].collection.updated_at)
	let buttonList = props.show
		? [
				['undo', 'redo'],
				[
					// ':i-More Misc-default.more_vertical',
					'font',
					'fontSize',
					'formatBlock',
				],
				['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
				['removeFormat'],
				['outdent', 'indent'],
				['align', 'list'],
				[
					'paragraphStyle',
					'blockquote',
					'table',
					'horizontalRule',
					'strike',
					'lineHeight',
					'link',
					'image',
					'video',
					'imageGallery',
					'codeView',
					'preview',
				],
		  ]
		: [
				['undo', 'redo'],
				[
					// ':i-More Misc-default.more_vertical',
					'font',
					'fontSize',
					'formatBlock',
				],
				['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
				['removeFormat'],
				['outdent', 'indent'],
				['align', 'list'],
		  ];
	const getSunEditorInstance = sunEditor => {
		editor.current = sunEditor;
	};
	const compressImage = async (file, secureUrl, info) => {
		new Compressor(file, {
			quality: 0.75,
			maxHeight: 2000,
			maxWidth: 2000,

			// The compression process is asynchronous,
			// which means you have to access the `result` in the `success` hook function.
			async success(result) {
				const formData = new FormData();

				formData.append('file', result, result.name);

				const imageUrl = secureUrl.split('?')[0];
				await fetch(secureUrl, {
					method: 'PUT',
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					body: result,
				});
				await fetch(
					`${process.env.REACT_APP_BASE_URL}/collections/addImageUrl/${authState.user.id}/${collectionState[props.uniqueKey].collection.id}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							imageUrl,
						}),
					}
				);
				const uploadFile = { name: 'name', size: 0 };
				const { core } = editor.current;
				if (info.isUpdate) {
					core.plugins.image.update_src.call(core, imageUrl, info.element, uploadFile);
				} else {
					core.plugins.image.create_image.call(core, imageUrl, info.linkValue, info.linkNewWindow, '', '', info.align, file);
				}

				core.closeLoading();
			},
			error(err) {
				console.log(err.message);
			},
		});
		// const imageUrl = secureUrl.split('?')[0];
		// await fetch(secureUrl, {
		// 	method: 'PUT',
		// 	headers: {
		// 		'Content-Type': 'multipart/form-data',
		// 	},
		// 	body: newFile.result,
		// });
		// await fetch(
		// 	`${process.env.REACT_APP_BASE_URL}/collections/addImageUrl/${authState.user.id}/${collectionState[props.uniqueKey].collection.id}`,
		// 	{
		// 		method: 'POST',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		body: JSON.stringify({
		// 			imageUrl,
		// 		}),
		// 	}
		// );
		// const uploadFile = { name: 'name', size: 0 };
		// const { core } = editor.current;
		// if (info.isUpdate) {
		// 	core.plugins.image.update_src.call(core, imageUrl, info.element, uploadFile);
		// } else {
		// 	core.plugins.image.create_image.call(
		// 		core,
		// 		imageUrl,
		// 		info.linkValue,
		// 		info.linkNewWindow,
		// 		info.inputWidth,
		// 		info.inputHeight,
		// 		info.align,
		// 		file
		// 	);
		// }

		// core.closeLoading();
	};
	const expireDate = date => {
		const exp = new Date(Date.parse(date));
		const expYear = exp.getFullYear();
		const expMonth = exp.getMonth() + 1;
		const expDay = exp.getDate();
		return `Save soon, expires on: ${expMonth}-${expDay}-${expYear}`;
	};
	const handleOnSubmit = e => {
		if (e) {
			e.preventDefault();
		}
		if (props.collectionNoteId === null) {
			handleCreateCN();
			return;
		}
		if (props.collection && props.collection.collection.user_id !== authState.user.id) {
			if (props.collection.collection.can_save) {
				const verseArr =
					collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection && collectionState[props.uniqueKey].collection.verses
						? collectionState[props.uniqueKey].collection.verses
						: props.collectionObj.verses
						? props.collectionObj.verses
						: null;
				handleSaveSharedCN(
					{
						uniqueKey: props.uniqueKey,
						studyHelpInfo: null,
						userId: props.user.id,
						title,
						text,
						categories,
						tags,
						originatedFrom: props.collection.collection.originated_from
							? props.collection.collection.originated_from
							: props.collection.collection.shared_by,
						verses: verseArr,
						read_only: false,
						ownerId: props.collection.collection.user_id,
						originCollectionId: props.collection.collection.id,
					},
					handleUpdateCn,
					props.verseAdded
				);
				editor.current.readOnly(false);
				props.socket.emit('update_cn_list', { fromVerse: false, userId: authState.user.id, uniqueKey: props.uniqueKey });
				return;
			} else {
				const verseArr =
					collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection && collectionState[props.uniqueKey].collection.verses
						? collectionState[props.uniqueKey].collection.verses
						: props.collectionObj.verses
						? props.collectionObj.verses
						: null;
				handleSaveSharedCN(
					{
						uniqueKey: props.uniqueKey,
						studyHelpInfo: null,
						userId: props.user.id,
						title,
						text,
						categories,
						tags,
						originatedFrom: props.collection.collection.originated_from
							? props.collection.collection.originated_from
							: props.collection.collection.shared_by,
						verses: verseArr,
						read_only: true,
						ownerId: props.collection.collection.user_id,
						originCollectionId: props.collection.collection.id,
					},
					handleUpdateCn,
					props.verseAdded
				);
				props.socket.emit('update_cn_list', { fromVerse: false, userId: authState.user.id, uniqueKey: props.uniqueKey });
			}
			return;
		}
		handleUpdateCN();
	};
	const handleRenderSaveBtn = () => {
		if (props.collectionNoteId === null) {
			return (
				<SaveCnBtn
					onClick={e => {
						e.preventDefault();
						handleCreateCN(e);
						// props.verseAdded();
					}}
					type='submit'
				>
					Save
				</SaveCnBtn>
			);
		}
		if (props.collection && authState.user && props.collection.collection.user_id !== authState.user.id) {
			if (isProUser()) {
				if (props.collection.collection.can_save) {
					return (
						<SaveCnBtn
							onClick={e => {
								e.preventDefault();
								const verseArr =
									collectionState[props.uniqueKey] &&
									collectionState[props.uniqueKey].collection &&
									collectionState[props.uniqueKey].collection.verses
										? collectionState[props.uniqueKey].collection.verses
										: props.collectionObj.verses
										? props.collectionObj.verses
										: null;

								handleSaveSharedCN(
									{
										uniqueKey: props.uniqueKey,
										studyHelpInfo: null,
										userId: props.user.id,
										title,
										text,
										categories,
										tags,
										originatedFrom: props.collection.collection.originated_from
											? props.collection.collection.originated_from
											: props.collection.collection.shared_by,
										verses: verseArr,
										read_only: false,
										ownerId: props.collection.collection.user_id,
										originCollectionId: props.collection.collection.id,
									},
									handleUpdateCn,
									props.verseAdded
								);
								editor.current.readOnly(false);
								props.socket.emit('update_cn_list', { fromVerse: false, userId: authState.user.id, uniqueKey: props.uniqueKey });
							}}
							type='submit'
						>
							Save
						</SaveCnBtn>
					);
				}
				if (props.collection.collection.recipient_saved) {
					return;
				} else
					return (
						<SaveCnBtn
							onClick={e => {
								e.preventDefault();
								const verseArr =
									collectionState[props.uniqueKey] &&
									collectionState[props.uniqueKey].collection &&
									collectionState[props.uniqueKey].collection.verses
										? collectionState[props.uniqueKey].collection.verses
										: props.collectionObj.verses
										? props.collectionObj.verses
										: null;

								handleSaveSharedCN(
									{
										uniqueKey: props.uniqueKey,
										studyHelpInfo: null,
										userId: props.user.id,
										title,
										text,
										categories,
										tags,
										originatedFrom: props.collection.collection.originated_from
											? props.collection.collection.originated_from
											: props.collection.collection.shared_by,
										verses: verseArr,
										read_only: true,
										ownerId: props.collection.collection.user_id,
										originCollectionId: props.collection.collection.id,
									},
									handleUpdateCn,
									props.verseAdded
								);
								props.socket.emit('update_cn_list', { fromVerse: false, userId: authState.user.id, uniqueKey: props.uniqueKey });
							}}
							type='submit'
						>
							Save
						</SaveCnBtn>
					);
			}
			return;
		}
		return (
			<SaveCnBtn
				type='submit'
				onClick={e => {
					handleUpdateCN(e);
				}}
			>
				Update
			</SaveCnBtn>
		);
	};
	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleOnSubmit();
			}}
			className='collectionNoteForm'
		>
			<LdsFootnoteSwitch>
				{
					<span>
						{/* {props.collectionNoteId === null ? (
							<SaveCnBtn
								onClick={e => {
									handleCreateCN(e);
								}}
								type='submit'
							>
								Save
							</SaveCnBtn>
						) : (
							<SaveCnBtn
								type='submit'
								onClick={e => {
									handleUpdateCN(e);
								}}
							>
								Update
							</SaveCnBtn>
						)} */}
						{handleRenderSaveBtn()}
					</span>
				}
				{/* Footnotes: User{' '}
				<Switch
					// onChange={() => props.handleToggleLdsFootnotes()}
					onChange={() => alert('Coming Soon')}
					checked={props.toggleLdsFootnotes}
					uncheckedIcon={false}
					checkedIcon={false}
					height={18}
					width={32}
				/>
				LDS */}
				{/* {collectionState[props.uniqueKey].collection.id && */}
				<Tooltip
					content={
						collectionState[props.uniqueKey].collection.id
							? `Last modified: ${updatedAt[1]}/${updatedAt[2]}/${updatedAt[0]} `
							: 'Save to modify date'
					}
					direction='right'
					forceDirection={true}
				>
					<div className='customDatePickerWidth'>
						<DatePicker selected={startDate} onChange={date => handleUpdateDate(date)} style={{ width: 25, height: 100 }} />
					</div>
				</Tooltip>
				{/* } */}
				<Tooltip
					content={props.customMarkup ? 'Click for regular verse markup' : 'Click for custom verse markup'}
					direction='right'
					forceDirection={true}
				>
					<CustomMarkupButton
						onClick={e => {
							e.preventDefault();
							props.handleCustomMarkup();
						}}
						style={props.customMarkup ? { backgroundColor: '#324599', color: '#fff', fontWeight: 800, borderRadius: '5px' } : {}}
					>
						CM
					</CustomMarkupButton>
				</Tooltip>
			</LdsFootnoteSwitch>
			{/* The classes on GrabPaneWrapper are from CollectionNoteForm.css */}
			<GrabPaneWrapper
				className='content-pane-title select-content-pane-title-wrapper collectionNoteForm_flexRow'
				{...props.dragHandleProps}
				style={{
					marginLeft: 0,
					paddingRight: 0,
				}}
			>
				<div
					className='collectionNoteForm_topRow-item'
					style={{
						width: !props.show && '100%',
						margin: !props.show && '0',
					}}
				>
					{/* {stripePlanName === 'Free' ? (
						<PremiumAccountBanner>
							You are currently on the <b>Free Plan</b>. Upgrade your plan to enjoy Premium features.
						</PremiumAccountBanner>
					) : (
							<div>
								{props.collectionNoteId === null ? (
								<SaveCnBtn onClick={e => {
									handleCreateCN(e);
									props.verseAdded()
								}} type='submit'>
									Save
								</SaveCnBtn>
							) : (
									<SaveCnBtn type='submit' onClick={e => {
										handleUpdateCN(e)
										props.verseAdded()
									}}>
										Update
									</SaveCnBtn>
									)}
									</div>
								)} */}

					<button
						style={{
							height: 45,
							width: 45,
							marginRight: '1%',
							fontSize: 12,
							backgroundColor: '#fff',
							// borderColor: '#cccccc',
							// borderWidth: '1px',
							border: '1px solid #cccccc',
							outline: 'none',
						}}
						onClick={e => {
							e.preventDefault();
							expandEditor ? setExpandEditor(false) : setExpandEditor(true);
						}}
					>
						{expandEditor ? 'Hide Editor' : 'Show Editor'}{' '}
					</button>
					<input
						className='collectionNoteForm_title-input'
						style={{ width: '100%', backgroundColor: '#ffffff' }}
						// style={{ width: !props.show ? '100%' : '12vw' }}
						placeholder='Title'
						disabled={canEdit()}
						type='text'
						value={title}
						onChange={e => setTitle(e.target.value)}
						autoComplete='off'
						autoFocus={false}
						required
					/>
				</div>
				{props.show && (
					<div className='collectionNoteForm_topRow-item'>
						<Creatable
							style={{ width: '100%' }}
							className='select-tag'
							isDisabled={canEdit('select')}
							placeholder='Select Tag or create'
							// isClearable
							isMulti
							isLoading={false}
							value={tags}
							options={allUsersTags.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
							onChange={handleTagChange}
							classNamePrefix='selectCategory'
						/>
					</div>
				)}
				{props.show && (
					<div className='collectionNoteForm_topRow-item'>
						<Select
							style={{ width: '100%' }}
							key='categories'
							isDisabled={canEdit('select')}
							className='select-category'
							placeholder='Select Category'
							isMulti={true}
							isClearable
							isSearchable
							value={categories}
							options={props.allCategories.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
							onChange={handleCategoryChange}
							classNamePrefix='selectCategory'
						/>
					</div>
				)}
			</GrabPaneWrapper>

			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{props.collection &&
					props.collection.collection &&
					props.collection.collection.study_help_name &&
					props.collection.collection.user_id === authState.user.id ? (
						<FromStudyHelp>
							<RemoveSpan onClick={handleRemoveStudyHelp}>X | </RemoveSpan>
							Created from {abbreviatedStudyHelp(props.collection.collection.study_help_name)} {props.collection.collection.study_help_topic}
						</FromStudyHelp>
					) : props.collectionObj && props.collectionObj.studyHelpInfo ? (
						<FromStudyHelp>
							Created from {abbreviatedStudyHelp(props.collectionObj.studyHelpInfo.studyHelpName)}{' '}
							{props.collectionObj.studyHelpInfo.studyHelpTopic}
						</FromStudyHelp>
					) : null}
					{props.collection &&
						props.collection.collection &&
						props.collection.collection.shared_by &&
						props.collection.collection.user_id !== authState.user.id && (
							<FromStudyHelp>
								Shared By{' '}
								{props.collection.collection.originated_from
									? props.collection.collection.originated_from
									: props.collection.collection.shared_by}
							</FromStudyHelp>
						)}

					{props.collection &&
						props.collection.collection &&
						props.collection.collection.share_settings &&
						props.collection.collection.user_id === authState.user.id && <SharedBanner>Shared</SharedBanner>}
					{props.collection &&
						props.collection.collection &&
						props.collection.collection.originated_from &&
						!props.collection.collection.shared_by && (
							<FromStudyHelp>
								{props.collection.collection.originated_from && <RemoveSpan onClick={handleRemoveSharedBy}>X | </RemoveSpan>}Shared By{' '}
								{props.collection.collection.originated_from}
							</FromStudyHelp>
						)}
					{props.collection &&
						props.collection.collection &&
						props.collection.collection.share_expires &&
						props.collection.collection.user_id !== authState.user.id &&
						!props.collection.collection.recipient_saved && (
							<Tooltip content={expireDate(props.collection.collection.share_expires)} direction='right' forceDirection={true}>
								<ExpiresBanner>Expires</ExpiresBanner>
							</Tooltip>
						)}
					{authState.user.id && canEdit() && (
						<Tooltip
							content={
								isProUser()
									? 'The owner has made this collection read only mode.'
									: `Free accounts can’t save collection notes. Please upgrade to pro.`
							}
							direction='up'
							forceDirection={true}
						>
							<ExpiresBanner>Read Only</ExpiresBanner>
						</Tooltip>
					)}
				</div>
				{/* {!canEdit() || !props.collection.collection.can_save && <ViewModeBanner />} */}
				{authState.user.id && !isProUser() && <FreeBanner />}
			</div>

			{/* <Scrollbars>
				<ScrollWrapper> */}
			{props.show === true ? (
				<TextEditorNUpdateNTogglePaneWrapper ref={editorWrapper}>
					{expandEditor ? (
						<SunEditor
							getSunEditorInstance={getSunEditorInstance}
							setDefaultStyle='color: #393f47; font-size: 15px; font-family: -apple-system, BlinkMacSystemFont, sans-serif;'
							setContents={text}
							height='150px'
							// onLoad={() => {
							// 	let a = document.getElementById('editor');
							// 	a.onmousedown = e => {
							// 		try {
							// 			const childrens = e.target.children;
							// 			const searchFor = 'IFRAME';
							// 			let newMode = 'edit';
							// 			for (let i = 0; i < childrens.length; i++) {
							// 				if (childrens[i].nodeName === searchFor) {
							// 					newMode = 'readonly';
							// 				}
							// 			}
							// 			setMode(newMode);
							// 		} catch (e) {
							// 			console.log(e);
							// 		}
							// 	};
							// }}

							autoFocus={false}
							// disable={canEdit()}
							// disable={true}
							setOptions={{
								imageFileInput: true,
								videoFileInput: false,
								height: 'auto',
								minHeight: '50px',
								maxHeight: '55vh',
								showPathLabel: false,
								charCounter: true,
								maxCharCount: 200000,
								// buttonList: buttonList
								buttonList: [
									['undo', 'redo'],
									[
										// ':i-More Misc-default.more_vertical',
										'font',
										'fontSize',
										'formatBlock',
									],
									['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
									['removeFormat'],
									['outdent', 'indent'],
									['align', 'list'],
									[
										'paragraphStyle',
										'blockquote',
										'table',
										'horizontalRule',
										'strike',
										'lineHeight',
										'link',
										'image',
										'video',
										'imageGallery',
										'codeView',
										'preview',
									],
								],
							}}
							onChange={handleEditorChange}
						/>
					) : null}
				</TextEditorNUpdateNTogglePaneWrapper>
			) : (
				<TextEditorNUpdateNTogglePaneWrapper ref={editorWrapper}>
					{expandEditor ? (
						<SunEditor
							getSunEditorInstance={getSunEditorInstance}
							setDefaultStyle='color: #393f47; font-size: 15px; font-family: -apple-system, BlinkMacSystemFont, sans-serif;'
							setContents={text}
							height='150px'
							// onLoad={() => {
							// 	let a = document.getElementById('editor');
							// 	a.onmousedown = e => {
							// 		try {
							// 			const childrens = e.target.children;
							// 			const searchFor = 'IFRAME';
							// 			let newMode = 'edit';
							// 			for (let i = 0; i < childrens.length; i++) {
							// 				if (childrens[i].nodeName === searchFor) {
							// 					newMode = 'readonly';
							// 				}
							// 			}
							// 			setMode(newMode);
							// 		} catch (e) {
							// 			console.log(e);
							// 		}
							// 	};
							// }}
							imageUploadHandler={(xmlHttpRequest, info, core) => console.log(xmlHttpRequest, info, core)}
							onPaste={(e, cleanData, maxCharCount) => console.log(e, cleanData, maxCharCount)}
							onImageUploadBefore={(files, info, uploader) => {
								//This gets the secure link that expires after 60 seconds to upload the image to the s3 bucket
								fetch(`${process.env.REACT_APP_BASE_URL}/s3/getSecureLink/${authState.user.id}`)
									.then(res => res.json())
									.then(async res => {
										//This function compresses the file and also handles the logic to upload the image to s3 and insert the image
										compressImage(files[0], res, info);
									});
							}}
							onImageUploadError={(e, result) => console.log('Image upload error:', { e, result })}
							autoFocus={false}
							// disable={canEdit()}
							setOptions={{
								imageFileInput: true,
								videoFileInput: false,
								height: 'auto',
								minHeight: '50px',
								maxHeight: '55vh',
								showPathLabel: false,
								charCounter: true,

								maxCharCount: 200000,
								// buttonList: buttonList
								buttonList: [
									['undo', 'redo'],
									[
										// ':i-More Misc-default.more_vertical',
										'font',
										'fontSize',
										'formatBlock',
									],
									['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
									['removeFormat'],
									['outdent', 'indent'],
									['align', 'list'],
									[
										'paragraphStyle',
										'blockquote',
										'table',
										'horizontalRule',
										'strike',
										'lineHeight',
										'link',
										'image',
										'video',
										'imageGallery',
										'codeView',
										'preview',
									],
								],
							}}
							onChange={handleEditorChange}
						/>
					) : null}
					{/* {expandEditor ? (
					!isProUser() ? (
						<JoditEditor
							ref={editor}
							value={initialText}
							config={props.show == false ? freeConfig : freeConfigShow}
							onBlur={(text, e) => {
								console.log(e);
								e.preventDefault()
								setText(text);
							}}
						// onBlur={handleEditorChange}
						// onChange={handleEditorChange}
						/>
					) : (
						<JoditEditor
							ref={editor}
							value={initialText}
							config={props.show == false ? proConfig : proConfigShow}
							onBlur={(text, e) => {
								console.log({
									editor: editor,
									editorWrapper,
									e: e.srcElement,
								});
								e.preventDefault()
								setText(text);
							}}
						// onChange={handleEditorChange}
						// onBlur={handleEditorChange}
						/>
					)
				) : null} */}

					{/* <UpdateNTogglePaneWrapper>
						{stripePlanName === 'Free' ? (
							<PremiumAccountBanner>
							You are currently on the <b>Free Plan</b>. Upgrade your plan to enjoy Premium features.
							</PremiumAccountBanner>
							) : (
								<div>
								{props.collectionNoteId === null ? (
									<SaveCnBtn onClick={e => handleCreateCN(e)} type='submit'>
									Save
									</SaveCnBtn>
									) : (
										<SaveCnBtn type='submit' onClick={e => handleUpdateCN(e)}>
										Update
										</SaveCnBtn>
										)}
										</div>
										)}
										<VerseAddedWrapper>{verseAdded}</VerseAddedWrapper>
										<ToggleSideWrapper>
										{props.show ? (
											<ToggleOpenPaneWrapper>
											<FontAwesomeIcon icon={faArrowLeft} onClick={() => props.handleExtendPane(true)} />
											</ToggleOpenPaneWrapper>
											) : (
												<ToggleOpenPaneWrapper>
												<FontAwesomeIcon icon={faArrowRight} onClick={() => props.handleExtendPane(false)} />
												</ToggleOpenPaneWrapper>
												)}
												
												{collection.verses && <span>{`${collection.verses.length}: Verses`}</span>}
												</ToggleSideWrapper>
											</UpdateNTogglePaneWrapper> */}
				</TextEditorNUpdateNTogglePaneWrapper>
			)}
			{/* </ScrollWrapper>
			</Scrollbars> */}
		</form>
	);
};

export default CollectionNoteEditor;
